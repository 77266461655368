import React from 'react';
import PropTypes from 'prop-types';
import AlertModal from 'components/common-components/AlertModal';
import Loader from 'components/common-components/Loader';

const ConfirmationModal = ({ show, title, message, actionList }) => {
  return (
    <AlertModal show={show} actionButtons={[]}>
      <div className='w-75 d-flex flex-column gap-4 align-items-center'>
        <label className='theme-size-1_5 text-center theme-font-inter-medium theme-text-black-1'>
          {title}
        </label>
        {message && (
          <label className='theme-size-1_2 text-center theme-font-inter-regular'>{message}</label>
        )}
        <div className='w-100 d-flex gap-2 justify-content-around mt-3'>
          {actionList.map((action, index) => (
            <div key={index} className='d-flex gap-2 align-items-center'>
              <label
                className={`theme-size-1_3 theme-font-inter-medium theme-text-${action.color}${
                  action.disabled ? ' opacity-50' : ' cursor-pointer'
                }`}
                onClick={() => (!(action.loading || action.disabled) ? action.onClick() : null)}
              >
                {action.text}
              </label>
              {action.loading && <Loader color={action.color} />}
            </div>
          ))}
        </div>
      </div>
    </AlertModal>
  );
};

ConfirmationModal.propTypes = {
  show: PropTypes.bool.isRequired,
  actionList: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.string,
};

export default ConfirmationModal;
