import React, { useState } from 'react';
import PropTypes from 'prop-types';
import crossGreyIcon from 'assets/images/icons/cross-grey.svg';
import AlertModal from 'components/common-components/AlertModal';
import modalOpenHook from './modalOpenHook';

const MediaPreviewModal = ({ displayedHtml }) => {
  const [mediaInfo, setMediaInfo] = useState(null);

  // Custom hooks
  modalOpenHook(displayedHtml, setMediaInfo);

  return (
    <AlertModal
      show={!!mediaInfo}
      actionButtons={[]}
      backdropClassName='backdrop-overlap'
      noPadding
      size='lg'
    >
      <div className='p-3 w-100'>
        {mediaInfo && (
          <>
            <div className='d-flex justify-content-between align-items-center mb-2'>
              <label className='theme-size-1_1'>
                <span>{mediaInfo.imgSrc ? 'Image: ' : mediaInfo.vidSrc ? 'Video: ' : ''}</span>
                {mediaInfo.mediaDate && <span>{mediaInfo.mediaDate}</span>}
              </label>
              <img
                src={crossGreyIcon}
                width={12}
                className='cursor-pointer'
                onClick={() => setMediaInfo(null)}
              />
            </div>
            <div className='d-flex justify-content-center align-items-center'>
              {mediaInfo.imgSrc ? (
                <img src={mediaInfo.imgSrc} style={{ maxWidth: '100%', maxHeight: '70vh' }} />
              ) : mediaInfo.vidSrc ? (
                <video id='modal-vid' controls style={{ maxWidth: '100%', maxHeight: '70vh' }}>
                  <source id='modal-video-source' type='video/mp4' src={mediaInfo.vidSrc} />
                  Your browser does not support the video tag.
                </video>
              ) : (
                <></>
              )}
            </div>
          </>
        )}
      </div>
    </AlertModal>
  );
};

MediaPreviewModal.propTypes = {
  displayedHtml: PropTypes.string,
};

export default MediaPreviewModal;
