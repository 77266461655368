import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import TableBody from './TableBody';
import TableHeader from './TableHeader';
import { isRTL } from 'config/config';

const TableComponent = ({
  header,
  filterEnabled,
  data,
  loader,
  props,
  isActionsEnabled,
  isSelectionEnabled,
  selectionKey,
  onSelection,
  selectedRows,
  debounceFunc,
  filteringData,
  setFilteringData,
  selectedCustomView,
}) => {
  let { mediaEnabled } = { ...props };
  const [sortedTableHeader, setSortedTableHeader] = useState([]);
  const [lastFieldIds, setLastFieldIds] = useState({});
  const htmlDirection = isRTL();
  // setting sticky columns width by targeting their "name"
  const [initialColsWidthMap, setInitialColsWidthMap] = useState({
    sr: {
      width: 40,
    },
    referenceNumber: {
      width: 90,
    },
    userName: {
      width: 190,
    },
    submitted: {
      width: 150,
    },
    submissionFlowState: {
      width: 155,
    },
    webView: {
      width: 105,
    },
    comments: {
      width: 120,
    },
    viewLogs: {
      width: 120,
    },
  });

  const widthOfCheckboxCol = 50;
  useEffect(() => {
    // delete 'viewLogs' column width only when disabled in form setting
    if (header.length && header.findIndex((obj) => obj?.name === 'viewLogs') === -1) {
      delete initialColsWidthMap['viewLogs'];
    }
    // adding left property to "initialColsWidthMap" for sticky columns
    let widthOfPreviousCols = widthOfCheckboxCol;
    Object.keys(initialColsWidthMap).forEach((key) => {
      const widthCurrentCol = initialColsWidthMap[key].width;
      initialColsWidthMap[key].left = widthOfPreviousCols;
      widthOfPreviousCols = widthOfPreviousCols + widthCurrentCol;
    });
    setInitialColsWidthMap((prevState) => {
      return { ...prevState };
    });
  }, [header]);

  const tableSortOrder = ['user', 'basicFields', 'supervisorFields'];

  // sorting the header data by tabletype like first show user, then basicFields and in last supervisorFields
  useEffect(() => {
    if (header.length > 0) {
      const sortedHeader = header.sort((a, b) => {
        const aOrder = tableSortOrder.indexOf(a?.tableType);
        const bOrder = tableSortOrder.indexOf(b?.tableType);
        return aOrder - bOrder;
      });
      setSortedTableHeader(
        sortedHeader.map((fieldHeader) =>
          fieldHeader.tableType === 'user'
            ? fieldHeader
            : {
                ...fieldHeader,
                isSelected: isFieldExistInCustomView(fieldHeader) || false,
              },
        ),
      );
    }
  }, [header, selectedCustomView]);

  useEffect(() => {
    if (sortedTableHeader.length) {
      const sectionEndFieldMap = {};
      const managerSectionEndFieldMap = {};
      const partitionEndFieldMap = {};
      for (let i = 0; i < sortedTableHeader.length; i++) {
        const { name, sectionTitle, tableType, isSelected } = sortedTableHeader[i];
        if (isSelected) {
          sectionEndFieldMap[sectionTitle] = name;
          if (tableType !== 'supervisorFields') {
            partitionEndFieldMap[tableType] = name;
          }
          if (tableType === 'supervisorFields') {
            managerSectionEndFieldMap[sectionTitle] = name;
          }
        }
      }
      const sectionIds = Object.values(sectionEndFieldMap).reduce((acc, item) => {
        acc[item] = true;
        return acc;
      }, {});
      const managerSectionIds = Object.values(managerSectionEndFieldMap).reduce((acc, item) => {
        acc[item] = true;
        return acc;
      }, {});
      const partitionIds = Object.values(partitionEndFieldMap).reduce((acc, item) => {
        acc[item] = true;
        return acc;
      }, {});
      setLastFieldIds({
        section: sectionIds,
        managerSection: managerSectionIds,
        partition: partitionIds,
      });
    }
  }, [sortedTableHeader]);

  // apply side border to each type of partition in table header
  const applyTablePartitionSideBorder = (fieldHeader) => {
    const { name } = fieldHeader;
    if (lastFieldIds.partition?.[name]) {
      return htmlDirection ? 'cell-border-left' : 'cell-border-right';
    }
    return '';
  };

  // apply side border to each section in "basicField" except the last one, in table header
  const applySecSideBorder = (fieldHeader) => {
    const { name, tableType } = fieldHeader;
    if (tableType === 'basicFields' || tableType === 'supervisorFields') {
      if (lastFieldIds.section?.[name]) {
        return htmlDirection ? 'cell-border-left' : 'cell-border-right';
      }
    }
    return '';
  };

  // apply side border to each manager section in table body
  const applyManagerSecPartitionBorder = (fieldHeader) => {
    const { name, tableType } = fieldHeader;
    if (tableType === 'supervisorFields') {
      if (lastFieldIds.managerSection?.[name]) {
        return htmlDirection ? 'cell-border-left' : 'cell-border-right';
      }
    }
    return '';
  };
  const [headerSectionTitles, setHeaderSectionTitles] = useState([]);
  useEffect(() => {
    if (sortedTableHeader.length) {
      let sectionNameArray = [];
      let current = null;
      sortedTableHeader.forEach((item) => {
        if (isFieldExistInCustomView(item)) {
          if (item.sectionTitle) {
            if (current && current.title === item.sectionTitle) {
              current.spanLength += 1;
            } else {
              if (current) sectionNameArray.push(current);
              current = { title: item.sectionTitle, spanLength: 1 };
            }
          } else {
            if (current) sectionNameArray.push(current);
            current = null;
            sectionNameArray.push({ title: '', spanLength: 1 });
          }
        }
      });
      // Push the last section if it exists
      if (current) sectionNameArray.push(current);
      setHeaderSectionTitles(sectionNameArray);
    }
  }, [sortedTableHeader, selectedCustomView]);

  const anySectionExists = (arr) => {
    const hasTitle = arr.some((obj) => obj.title.length > 0);
    if (hasTitle) return true;
    return false;
  };

  const isFieldExistInCustomView = (headerField) => {
    const { customViewId, viewsList } = selectedCustomView;
    if (customViewId === 'full_view') return true;
    if (customViewId === 'manager_view' && headerField.tableType !== 'basicFields') {
      return true;
    }
    return headerField.tableType === 'user' || viewsList[customViewId]?.includes(headerField.name);
  };

  return (
    <div className='d-flex'>
      <div className='overflow-auto position-relative'>
        <div className='table-responsive maintain-height maintain-max-height submission-table'>
          <table
            className={`table-submission ${
              headerSectionTitles?.length > 0 && anySectionExists(headerSectionTitles)
                ? 'adjust-table'
                : ''
            }`}
            style={{ direction: htmlDirection ? 'rtl' : 'ltr' }}
          >
            <TableHeader
              filterEnabled={filterEnabled}
              mediaEnabled={mediaEnabled ?? false}
              tableFields={sortedTableHeader}
              onSelection={onSelection}
              isSelectionEnabled={isSelectionEnabled}
              isActionsEnabled={isActionsEnabled}
              selectionKey={selectionKey}
              selectionValue={
                selectedRows && selectedRows.length > 0
                  ? selectedRows.length === data.length
                  : false
              }
              debounceFunc={debounceFunc}
              filteringData={filteringData}
              setFilteringData={setFilteringData}
              headerSectionTitles={headerSectionTitles}
              anySectionExists={anySectionExists}
              initialColsWidthMap={initialColsWidthMap}
              widthOfCheckboxCol={widthOfCheckboxCol}
              applyTablePartitionSideBorder={applyTablePartitionSideBorder}
              applySecSideBorder={applySecSideBorder}
              htmlDirection={htmlDirection}
            />
            <TableBody
              onSelection={onSelection}
              data={data}
              tableFields={sortedTableHeader}
              loader={loader}
              props={props}
              isSelectionEnabled={isSelectionEnabled}
              isActionsEnabled={isActionsEnabled}
              selectionKey={selectionKey}
              selectedRows={selectedRows}
              initialColsWidthMap={initialColsWidthMap}
              widthOfCheckboxCol={widthOfCheckboxCol}
              applyTablePartitionSideBorder={applyTablePartitionSideBorder}
              applyManagerSecPartitionBorder={applyManagerSecPartitionBorder}
              applySecSideBorder={applySecSideBorder}
              htmlDirection={htmlDirection}
              classes={{
                adjustTableBody:
                  headerSectionTitles?.length > 0 && anySectionExists(headerSectionTitles)
                    ? 'adjust-tablebody'
                    : '',
              }}
            />
          </table>
        </div>
      </div>
    </div>
  );
};
TableComponent.propTypes = {
  selectedRows: PropTypes.array,
  header: PropTypes.array.isRequired,
  filterEnabled: PropTypes.bool.isRequired,
  data: PropTypes.array.isRequired,
  loader: PropTypes.bool.isRequired,
  props: PropTypes.object.isRequired,
  isActionsEnabled: PropTypes.bool.isRequired,
  isSelectionEnabled: PropTypes.bool,
  selectionKey: PropTypes.string,
  onSelection: PropTypes.func,
  debounceFunc: PropTypes.func,
  filteringData: PropTypes.object,
  setFilteringData: PropTypes.func,
  selectedCustomView: PropTypes.object.isRequired,
};
export default TableComponent;
