import { useEffect } from 'react';

const modalOpenHook = (displayedHtml, setMediaInfo) => {
  useEffect(() => {
    if (displayedHtml) {
      // Add event listeners to all anchor elements inside .videos and .images classes
      document.querySelectorAll('.videos a, .images a').forEach(function (anchor) {
        // When any of the anchor tags are clicked
        anchor.addEventListener('click', function (e) {
          e.preventDefault();
          let dateContent = anchor.getAttribute('data-date');
          // Handle image media type
          let imgSrc = anchor.getAttribute('data-img');
          // Handle video media type
          let vidSrc = anchor.getAttribute('data-vid');

          setMediaInfo({ mediaDate: dateContent ?? null, imgSrc, vidSrc });
        });
      });
    }
  }, [displayedHtml]);
};

export default modalOpenHook;
