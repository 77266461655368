import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import TextField from 'components/form-components/TextField';
import SingleCheckBoxField from 'components/form-components/SingleCheckBoxField';
import submissionAttachment from 'assets/images/icons/submission-attachment.svg';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import tier1Icon from 'assets/images/icons/tier1.svg';
import tier2Icon from 'assets/images/icons/tier2.svg';
import crossGreenIcon from 'assets/images/icons/cross.svg';

const TableHeader = ({
  filterEnabled,
  mediaEnabled,
  tableFields,
  onSelection,
  isActionsEnabled,
  isSelectionEnabled,
  selectionValue,
  debounceFunc,
  filteringData,
  setFilteringData,
  headerSectionTitles,
  anySectionExists,
  initialColsWidthMap,
  widthOfCheckboxCol,
  applyTablePartitionSideBorder,
  applySecSideBorder,
  htmlDirection,
}) => {
  const [hoverTitleShowMap, setHoverTitleShowMap] = useState({});
  const [initialColsWidthMapModified, setInitialColsWidthMapModified] = useState({});
  const nonStickyColsWidth = 150; // width in pixels
  const [showHierarchyView, setShowHierarchyView] = useState({});

  // show only the selected box and close all others
  const toggleOverlay = (index) => {
    setShowHierarchyView((prevState) => {
      const newState = { ...prevState };
      Object.keys(prevState).forEach((key) => {
        newState[key] = false;
      });
      newState[index] = !prevState[index];
      return newState;
    });
  };

  useEffect(() => {
    // Convert to an object with numeric string keys
    const newObj = Object.values(initialColsWidthMap).reduce((acc, value, index) => {
      acc[index] = value;
      return acc;
    }, {});
    setInitialColsWidthMapModified(newObj);
  }, [initialColsWidthMap]);

  const toggleShowMap = (name) =>
    setHoverTitleShowMap({ ...hoverTitleShowMap, [name]: !(hoverTitleShowMap[name] ?? false) });

  const popover = ({ fullFieldTitle }) => (
    <Popover className='min-width-176' id='popover-basic'>
      <Popover.Body>
        <label>{fullFieldTitle}</label>
      </Popover.Body>
    </Popover>
  );

  return (
    <thead
      className={`${
        headerSectionTitles?.length > 0 && anySectionExists(headerSectionTitles)
          ? 'adjust-tableheader'
          : ''
      }`}
    >
      {filterEnabled && (
        <tr>
          {isSelectionEnabled && <th className='filter-field'></th>}
          {tableFields.map((singleHeaderField, index) => {
            return singleHeaderField.isSelected ? (
              <th key={index} className='filter-field'>
                {singleHeaderField.isFilterAble ? (
                  <TextField
                    value={
                      filteringData[singleHeaderField.name]
                        ? filteringData[singleHeaderField.name]
                        : ''
                    }
                    name={singleHeaderField.name}
                    debounceFunc={debounceFunc}
                    handleChange={setFilteringData}
                    placeholder=''
                    shrink
                    classes='filter-field'
                  />
                ) : (
                  ''
                )}
              </th>
            ) : null;
          })}
          {isActionsEnabled && <th className='filter-field'></th>}
        </tr>
      )}
      <tr className='header-row'>
        <th
          className='table-checkbox sticky-cell'
          style={{
            width: `${widthOfCheckboxCol}px`,
            ...{
              [htmlDirection ? 'right' : 'left']: '0px',
            },
          }}
        >
          <SingleCheckBoxField
            name={'all'}
            value={selectionValue}
            handleChange={onSelection}
            text={<></>}
            classes=''
          />
        </th>
        {tableFields.map((singleHeaderField, index) => {
          return singleHeaderField.isSelected ? (
            <th
              key={index}
              className={`nowrap ${
                initialColsWidthMap[singleHeaderField?.name]
                  ? 'sticky-cell'
                  : 'middle-columns non-sticky-cell'
              } ${singleHeaderField?.sectionTitle ? 'cell-border-top' : ''} ${applySecSideBorder(
                singleHeaderField,
              )} ${applyTablePartitionSideBorder(singleHeaderField)}`}
              style={{
                width: initialColsWidthMap[singleHeaderField.name]
                  ? `${initialColsWidthMap[singleHeaderField.name].width}px`
                  : `${nonStickyColsWidth}px`,
                ...(initialColsWidthMap[singleHeaderField.name] && {
                  [htmlDirection ? 'right' : 'left']: `${
                    initialColsWidthMap[singleHeaderField.name].left
                  }px`,
                }),
              }}
            >
              {['basicFields', 'supervisorFields'].includes(singleHeaderField.tableType) ? (
                <div className='d-flex align-items-center overflow-hidden'>
                  {singleHeaderField.hierarchy?.length > 0 && (
                    <HierarchyView
                      singleHeaderField={singleHeaderField}
                      htmlDirection={htmlDirection}
                      showHierarchyView={showHierarchyView}
                      toggleOverlay={toggleOverlay}
                      index={index}
                    />
                  )}

                  <OverlayTrigger
                    trigger={['hover', 'focus']}
                    onToggle={() => toggleShowMap(singleHeaderField.name)}
                    show={hoverTitleShowMap[singleHeaderField.name]}
                    placement='top'
                    rootClose
                    overlay={popover({
                      fullFieldTitle:
                        singleHeaderField.hintText && singleHeaderField.hintText !== ''
                          ? singleHeaderField.hintText
                          : singleHeaderField.displayName,
                    })}
                  >
                    <div className=''>
                      <TableHeaderColumn
                        mediaEnabled={mediaEnabled}
                        singleHeaderField={singleHeaderField}
                        htmlDirection={htmlDirection}
                      />
                    </div>
                  </OverlayTrigger>
                </div>
              ) : (
                <TableHeaderColumn
                  mediaEnabled={mediaEnabled}
                  singleHeaderField={singleHeaderField}
                />
              )}
            </th>
          ) : null;
        })}
        {isActionsEnabled && <th></th>}
        {/* add extra column to take remaining width of the column from 100% width of column */}
        <th className='d-flex flex-grow-1'></th>
      </tr>

      {headerSectionTitles?.length > 0 && anySectionExists(headerSectionTitles) && (
        <tr className='section-titles-row'>
          <th
            className='table-checkbox sticky-cell'
            style={{ [htmlDirection ? 'right' : 'left']: '0px', width: '50px' }}
          ></th>
          {headerSectionTitles.map((data, index) => {
            return (
              <th
                key={index}
                className={`nowrap text-center ${
                  initialColsWidthMapModified[index] != undefined
                    ? 'sticky-cell'
                    : 'middle-columns non-sticky-cell'
                }`}
                style={{
                  width: initialColsWidthMapModified[index]
                    ? `${initialColsWidthMapModified[index].width}px`
                    : `${nonStickyColsWidth}px`,
                  ...(initialColsWidthMapModified[index] && {
                    [htmlDirection
                      ? 'right'
                      : 'left']: `${initialColsWidthMapModified[index].left}px`,
                  }),
                }}
                colSpan={data?.spanLength}
              >
                {data?.title}
              </th>
            );
          })}
          <th className='d-flex flex-grow-1'></th>
        </tr>
      )}
    </thead>
  );
};

TableHeader.propTypes = {
  tableFields: PropTypes.array.isRequired,
  onSelection: PropTypes.func,
  filterEnabled: PropTypes.bool.isRequired,
  mediaEnabled: PropTypes.bool,
  isSelectionEnabled: PropTypes.bool,
  isActionsEnabled: PropTypes.bool.isRequired,
  selectionValue: PropTypes.bool.isRequired,
  debounceFunc: PropTypes.func,
  filteringData: PropTypes.object,
  setFilteringData: PropTypes.func,
  headerSectionTitles: PropTypes.array,
  anySectionExists: PropTypes.func,
  initialColsWidthMap: PropTypes.object,
  widthOfCheckboxCol: PropTypes.number,
  applyTablePartitionSideBorder: PropTypes.func,
  applySecSideBorder: PropTypes.func,
  htmlDirection: PropTypes.bool,
};

export default TableHeader;

const TableHeaderColumn = ({ singleHeaderField, mediaEnabled, htmlDirection }) => {
  const { t } = useTranslation();

  return (
    <div className='d-flex justify-content-between main-div' data-field-id={singleHeaderField.name}>
      <span>{t(singleHeaderField.displayName)}</span>

      {singleHeaderField?.isMediaAttached && mediaEnabled && (
        <div style={{ position: 'absolute', [htmlDirection ? 'left' : 'right']: '0' }}>
          <img className='ps-2 pe-3 theme-background-white-2' src={submissionAttachment} />
        </div>
      )}
    </div>
  );
};

TableHeaderColumn.propTypes = {
  singleHeaderField: PropTypes.object.isRequired,
  mediaEnabled: PropTypes.bool,
  htmlDirection: PropTypes.bool,
};

const HierarchyView = ({
  singleHeaderField,
  htmlDirection,
  showHierarchyView,
  index,
  toggleOverlay,
}) => {
  return (
    <div key={index} className={`d-flex align-items-center`}>
      <OverlayTrigger
        placement='bottom'
        overlay={
          <div className='popover' id='popover-basic'>
            <div
              className='d-flex justify-content-center align-items-baseline py-2 cursor-pointer'
              onClick={() => toggleOverlay(index)}
            >
              <img src={crossGreenIcon} style={{ width: '0.7em', height: 'auto' }} alt='Close' />
              <span className='mx-2 theme-text-green-1'>Close</span>
            </div>
            <div className='popover-body d-flex gap-2 px-3 pb-2 pt-0'>
              <div
                className={`border-green-1-h-2 border-${
                  htmlDirection ? 'start' : 'end'
                }-0 px-1 pt-1`}
              ></div>
              <div className='flex-grow-1 pt-1'>
                <div className='d-flex gap-2 align-items-center'>
                  <label className=''>{singleHeaderField.hierarchy[0] || ''}</label>
                </div>
                {singleHeaderField.hierarchy.length > 1 && (
                  <div className='d-flex gap-2 mt-1'>
                    <div
                      className={`border-green-1-h-2 border-${
                        htmlDirection ? 'start' : 'end'
                      }-0 px-1 pt-1`}
                    ></div>
                    <div className='flex-grow-1'>
                      <div className='d-flex gap-2 align-items-center'>
                        <label className=''>{singleHeaderField.hierarchy[1] || ''}</label>
                      </div>
                      {singleHeaderField.hierarchy.length > 2 && (
                        <div className='d-flex gap-2 mt-1'>
                          <div
                            className={`border-green-1-h-2 border-${
                              htmlDirection ? 'start' : 'end'
                            }-0 px-1 pt-1`}
                          ></div>
                          <div className='flex-grow-1'>
                            <div className='d-flex gap-2 align-items-center'>
                              <label className=''>{singleHeaderField.hierarchy[2] || ''}</label>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        }
        trigger={['click']}
        show={showHierarchyView[index]} // Show only for the specific index
        rootClose={true}
        delay={{ show: 50, hide: 250 }}
        flip={true}
        popperConfig={{
          modifiers: [
            {
              name: 'preventOverflow',
              options: {
                boundary: 'viewport',
              },
            },
          ],
        }}
      >
        <img
          src={singleHeaderField.hierarchy.length === 2 ? tier1Icon : tier2Icon}
          onClick={() => toggleOverlay(index)} // Toggle the overlay for this specific item
          alt={`Tier ${index + 1}`}
          className={`cursor-pointer ${htmlDirection ? 'ms-2' : 'me-2'}`}
        />
      </OverlayTrigger>
    </div>
  );
};

HierarchyView.propTypes = {
  singleHeaderField: PropTypes.object.isRequired,
  htmlDirection: PropTypes.bool,
  showHierarchyView: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  toggleOverlay: PropTypes.func.isRequired,
};
