import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import dropDownArrowGrey from 'assets/images/icons/dropdown-arrow-grey.svg';
import dropDownArrowWhite from 'assets/images/icons/dropdown-arrow-white.svg';
import CreateViewModal from './CreateViewModal';
import ShowViewListModal from './ShowViewListModal';
import { CallAPI } from 'actions/General';
import ManageViewListModal from './ManageViewListModal';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const CustomViews = ({
  values,
  setValues,
  submissionsHeaderList,
  filterData,
  formName,
  setSelectedCustomView,
}) => {
  const { t } = useTranslation();
  const auth = useSelector((state) => state.auth);
  const currentUserId = auth.profile.userCompanyId;
  const [viewData, setViewData] = useState([]);
  const [currentUserViewData, setCurrentUserViewData] = useState([]);
  const [currentView, setCurrentView] = useState('full_view');
  const [showViewModal, setShowViewModal] = useState({
    showCreateViewModal: false,
    showViewListModal: false,
    showManageViewModal: false,
  });

  const viewsTabOptionList = [
    { value: 'full_view', displayValue: 'text_full_view' },
    { value: 'manager_view', displayValue: 'text_manager_view' },
    { value: 'custom_view', displayValue: 'text_custom_view' },
  ];
  const handleViewChange = (view) => {
    if (view === 'full_view' || view === 'manager_view') {
      setCurrentView(view);
      if (
        showViewModal.showCreateViewModal ||
        showViewModal.showViewListModal ||
        showViewModal.showManageViewModal
      ) {
        setShowViewModal((prevState) => ({
          ...prevState,
          showCreateViewModal: false,
          showViewListModal: false,
          showManageViewModal: false,
        }));
      }
    } else {
      if (
        showViewModal.showCreateViewModal ||
        showViewModal.showViewListModal ||
        showViewModal.showManageViewModal
      ) {
        setShowViewModal((prevState) => ({
          ...prevState,
          showCreateViewModal: false,
          showViewListModal: false,
          showManageViewModal: false,
        }));
      } else if (viewData.length) {
        setShowViewModal((prevState) => ({
          ...prevState,
          showViewListModal: true,
        }));
      } else {
        setShowViewModal((prevState) => ({
          ...prevState,
          showCreateViewModal: true,
        }));
      }
    }
  };

  const fetchCustomViews = async () => {
    let result = await CallAPI(
      'FETCH_REPORT_CUSTOM_VIEWS',
      {
        formId: filterData.formId,
      },
      null,
      null,
      null,
    );
    if (result.status) {
      const finalArray = [];
      if (result.data.customViews.length) {
        const groupedData = submissionsHeaderList.reduce((acc, item) => {
          if (item.tableType === 'user' || item.tableType === 'basicFields') {
            // For user or basicFields, group them under "user"
            if (!acc.user) {
              acc.user = {
                groupKey: 'user',
                headers: [],
              };
            }
            acc.user.headers.push(item);
          } else if (item.tableType === 'supervisorFields') {
            // For supervisorFields, group them by sectionTitle
            const groupKey = item.sectionTitle;
            if (!acc[groupKey]) {
              acc[groupKey] = {
                groupKey: groupKey,
                headers: [],
              };
            }
            acc[groupKey].headers.push(item);
          }
          return acc;
        }, {});

        const groupedHeaders = Object.values(groupedData); // Convert the grouped object into an array

        for (const item of result.data.customViews) {
          const views = JSON.parse(item.views);

          const excludedGroupKeys = [];
          for (const { groupKey, headers } of groupedHeaders) {
            for (const header of headers) {
              if (
                (header.tableType === 'basicFields' || header.tableType === 'supervisorFields') &&
                !views.includes(header.name) &&
                !excludedGroupKeys.includes(groupKey)
              ) {
                excludedGroupKeys.push(groupKey);
              }
            }
          }
          const includedGroupKeys = groupedHeaders
            .map((item) => item.groupKey)
            .filter((groupKey) => !excludedGroupKeys.includes(groupKey));

          finalArray.push({
            createdByUser: item.createdByUser,
            createdByUserName: item.createdByUserName,
            name: item.name,
            restricted: item.restricted,
            selectedFields:
              views.reduce((acc, key) => {
                acc[key] = true;
                return acc;
              }, {}) || {},
            selectedGroups:
              includedGroupKeys.reduce((acc, key) => {
                acc[key] = true;
                return acc;
              }, {}) || {},
            updatedAt: item.updatedAt,
            _id: item._id,
          });
        }
        setViewData(finalArray);
      } else {
        setViewData([]);
      }
    }
  };

  const handleAddOrUpdateCustomView = async (props, existingViewId) => {
    const payload = {
      formId: filterData.formId,
      formTitle: formName,
      ...props,
    };
    if (existingViewId) {
      let result = await CallAPI('UPDATE_REPORT_CUSTOM_VIEWS', props, existingViewId, null, null);
      if (result.status) {
        fetchCustomViews();
      }
    } else {
      let result = await CallAPI('ADD_REPORT_CUSTOM_VIEWS', payload, null, null, null);
      if (result.status) {
        fetchCustomViews();
      }
    }
  };

  const handleDeleteCustomView = async (viewId) => {
    let result = await CallAPI('DELETE_REPORT_CUSTOM_VIEWS', null, viewId, null, null);
    if (result.status) {
      fetchCustomViews();
    }
  };

  useEffect(() => {
    if (filterData.formId && submissionsHeaderList.length) {
      fetchCustomViews();
    }
  }, [filterData.formId, submissionsHeaderList]);

  useEffect(() => {
    const viewIdAndFieldMap = viewData.reduce((acc, item) => {
      acc[item._id] = Object.keys(item.selectedFields);
      return acc;
    }, {});
    setSelectedCustomView({
      customViewId: currentView,
      viewsList: viewIdAndFieldMap,
    });
    //apply the updated filter if exist otherwise show all elements
    const selectedView = viewData.find((obj) => obj._id === currentView);
    if (!(currentView === 'full_view' || currentView === 'manager_view') && !selectedView) {
      // if customView deleted only then go full view
      setCurrentView('full_view');
    }
  }, [currentView, viewData]);

  useEffect(() => {
    // filter views created by loggedIn user
    setCurrentUserViewData(viewData.filter((obj) => obj.createdByUser === currentUserId));
  }, [viewData]);

  return (
    <div>
      <div className={`tab-selection-field text-nowrap`}>
        {viewsTabOptionList.map((option, index) => (
          <div
            key={index}
            className={`px-2 ${
              (!['full_view', 'manager_view'].includes(option.value) &&
                !['full_view', 'manager_view'].includes(currentView)) ||
              option.value === currentView
                ? 'active'
                : 'theme-background-white-1'
            }`}
            onClick={() => handleViewChange(option.value)}
          >
            {t(option.displayValue)}
            {option.value === 'custom_view' && (
              <img
                src={
                  currentView === 'full_view' || currentView === 'manager_view'
                    ? dropDownArrowGrey
                    : dropDownArrowWhite
                }
                className='mx-2'
              />
            )}
          </div>
        ))}
      </div>
      {showViewModal.showCreateViewModal && (
        <CreateViewModal
          values={values}
          setValues={setValues}
          setShowViewModal={setShowViewModal}
          submissionsHeaderList={submissionsHeaderList}
          viewData={viewData}
          handleAddOrUpdateCustomView={handleAddOrUpdateCustomView}
        />
      )}
      {showViewModal.showViewListModal && viewData?.length > 0 && (
        <ShowViewListModal
          viewData={viewData}
          setShowViewModal={setShowViewModal}
          currentView={currentView}
          setCurrentView={setCurrentView}
        />
      )}
      {showViewModal.showManageViewModal && viewData?.length > 0 && (
        <ManageViewListModal
          viewData={currentUserViewData}
          setValues={setValues}
          setShowViewModal={setShowViewModal}
          handleDeleteCustomView={handleDeleteCustomView}
        />
      )}
    </div>
  );
};

CustomViews.propTypes = {
  values: PropTypes.object.isRequired,
  setValues: PropTypes.func.isRequired,
  submissionsHeaderList: PropTypes.array.isRequired,
  filterData: PropTypes.object.isRequired,
  formName: PropTypes.string.isRequired,
  setSelectedCustomView: PropTypes.func.isRequired,
};
export default CustomViews;
