import React from 'react';
import PropTypes from 'prop-types';
import plusIcon from 'assets/images/icons/plus.svg';
import crossGreyIcon from 'assets/images/icons/cross-grey.svg';
import TableComponent from 'components/table-component/Table';
import { manageViewsTableHeader } from '../utils';
import { useTranslation } from 'react-i18next';

const ManageViewListModal = ({ viewData, setValues, setShowViewModal, handleDeleteCustomView }) => {
  const { t } = useTranslation();

  const editDeleteView = async (_id, type) => {
    if (type === 'EDIT') {
      const selectedView = viewData.find((existingView) => existingView._id === _id);
      setValues({
        _id,
        name: selectedView.name,
        restricted: selectedView.restricted,
        selectedFields: selectedView.selectedFields,
        selectedGroups: selectedView.selectedGroups,
      });
      setShowViewModal((prevState) => ({
        ...prevState,
        showManageViewModal: false,
        showCreateViewModal: true,
      }));
    } else if (type === 'DELETE') {
      await handleDeleteCustomView(_id);
    }
  };

  return (
    <div className='manage-view-modal'>
      <div className='py-0 px-0'>
        <div className='manage-view-header'>
          <div>{t('text_manage_custom_views')}</div>
          <div
            className='theme-text-green-1 cursor-pointer'
            onClick={() =>
              setShowViewModal((prevState) => ({
                ...prevState,
                showManageViewModal: false,
                showCreateViewModal: true,
              }))
            }
          >
            <img src={plusIcon} />
            {t('text_add_new_view')}
          </div>
          <div>
            <img
              src={crossGreyIcon}
              className='cursor-pointer'
              onClick={() =>
                setShowViewModal((prevState) => ({
                  ...prevState,
                  showManageViewModal: false,
                }))
              }
            />
          </div>
        </div>
        <TableComponent
          header={manageViewsTableHeader}
          filterEnabled={false}
          data={viewData}
          totalRecords={viewData.length}
          loader={false}
          props={{
            editDeleteView,
          }}
          isFieldSelectionEnabled={false}
          isPaginationEnabled={false}
          isActionsEnabled={false}
          maintainTableHeight={false}
        />
      </div>
    </div>
  );
};

ManageViewListModal.propTypes = {
  viewData: PropTypes.array.isRequired,
  setValues: PropTypes.func.isRequired,
  setShowViewModal: PropTypes.func.isRequired,
  handleDeleteCustomView: PropTypes.func.isRequired,
};
export default ManageViewListModal;
