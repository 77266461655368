import eyeIcon from 'assets/images/announcements/eye.svg';
import eyeOffIcon from 'assets/images/icons/eye-off.svg';
import editGreenIcon from 'assets/images/icons/edit-green.svg';
import trashRedIcon from 'assets/images/icons/trash-red.svg';
import arrowIcon from 'assets/images/icons/arrow-right-green.svg';
import DropDownArrowGreen from 'assets/images/icons/dropdown-arrow-green.svg';
import formEditIcon from 'assets/images/icons/form-edit-icon.svg';
import webViewIcon from 'assets/images/icons/pdf-page-grey.svg';
import commentsIcon from 'assets/images/icons/message-square-grey.svg';
import editSquareGreyIcon from 'assets/images/icons/edit-square-grey.svg';
import trashGreyIcon from 'assets/images/icons/trash-grey.svg';
import profileEditGreenIcon from 'assets/images/icons/profile-edit-green-icon.svg';
import profileLockIcon from 'assets/images/icons/profile-lock-icon.svg';
import locationIcon from 'assets/images/icons/location.svg';
import formNameImage from 'assets/images/icons/form-name-image.jpg';
import starFilledIcon from 'assets/images/static-icons/star-filled.svg';
import starHollowIcon from 'assets/images/static-icons/star-hollow.svg';
import DataSourceActions from 'pages/datasource/database/DataSourceActions';
import EntriesActions from 'pages/datasource/datasourceList/EntriesActions';
import FormActions from 'pages/forms/Actions';
import GroupActions from 'pages/group/Actions';
import Initials from 'components/common-components/Initials';
import NumberRangeField from 'components/form-components/NumberRangeField';
import SelectField from 'components/form-components/SelectField';
import SubmissionActions from 'pages/submissions/Actions';
import UserActions from 'pages/users/Actions';
import VersionActions from 'pages/forms/VersionActions';
import { formTypeIconValues } from 'pages/forms/utils';
import { adminAccessLevel, permissionKeys } from 'config/config';
import TrashActions from 'pages/trash/TrashActions';
import FormButton from 'components/form-components/FormButton';
import { isPermissionAvailable } from 'config/permissionUtils';
import SingleCheckBoxField from 'components/form-components/SingleCheckBoxField';
import _ from 'lodash';

const userInitials = (row) => {
  let initial = '';
  if (row.user && row.user.firstName) initial += row.user.firstName[0];
  if (row.user && row.user.lastName) initial += row.user.lastName[0];
  return <div className='profileImage text-uppercase user-select-none'>{initial}</div>;
};
const populateTags = (tags) => {
  return (
    <>
      {tags && tags.length > 0 ? (
        <div className='d-flex flex-row width-max-content justify-content-center gap-3'>
          {tags.length > 0 && (
            <>
              <div className='width-max-content theme-background-green-3 rounded-pill py-2 px-4'>
                {tags[0]}
              </div>
              {tags.length > 1 && (
                <div className='w-auto theme-background-green-3 rounded-pill  p-2 '>
                  + {tags.length - 1}
                </div>
              )}
            </>
          )}
        </div>
      ) : (
        <></>
      )}
    </>
  );
};
const populateDate = (date) => {
  return date ? new Date(date).toLocaleDateString('en-US') : '';
};
const populateTime = (date) => {
  return date ? new Date(date).toLocaleTimeString('en-US') : '';
};
const populateDateTime = (date) => {
  return date ? new Date(date).toLocaleString('en-US') : '';
};

const populateDateTimeForSubmission = (date) => {
  return date ? (
    <div className='d-flex flex-column'>
      <span>{new Date(date).toLocaleDateString('en-US')}</span>
      <span>
        {new Date(date).toLocaleTimeString('en-US', {
          hour: 'numeric',
          minute: 'numeric',
          hour12: true,
        })}
      </span>
    </div>
  ) : (
    ''
  );
};

const populateUnixToDate = (date) => {
  return date ? new Date(parseInt(date)).toLocaleDateString('en-US') : '';
};
const populateUnixToTime = (date) => {
  return date ? new Date(parseInt(date)).toLocaleTimeString('en-US') : '';
};
const populateUnixToDateTime = (date) => {
  return date ? new Date(parseInt(date)).toLocaleString('en-US') : '';
};
function timeSince(date) {
  const seconds = Math.floor((new Date() - date) / 1000);

  let interval = seconds / 31536000;

  if (interval > 1) {
    return Math.floor(interval) + ' years';
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + ' months';
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + ' days';
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + ' hours';
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + ' minutes';
  }
  return Math.floor(seconds) + ' seconds';
}
const populateTimeAgo = (row) => {
  return `${timeSince(new Date(row.endDate ?? row.startDate))} ago`;
};

const secondsToHoursMinSeconds = (totalSeconds) => {
  let remainingSeconds = totalSeconds;
  const hours = Math.floor(remainingSeconds / 3600);
  remainingSeconds = remainingSeconds % 3600;
  const minutes = Math.floor(remainingSeconds / 60);
  const seconds = remainingSeconds % 60;

  return { hours, minutes, seconds };
};

const populateTotalHours = (totalSeconds) => {
  const { hours, minutes, seconds } = secondsToHoursMinSeconds(totalSeconds);

  return totalSeconds
    ? `${hours < 10 ? '0' : ''}${hours}:${minutes < 10 ? '0' : ''}${minutes}:${
        seconds < 10 ? '0' : ''
      }${seconds}`
    : '';
};

const populateKeyFilterName = (dataSourceSchema) => {
  const keyFilterName = dataSourceSchema?.find((value) => value.isKeyFilter)?.columnName;
  return keyFilterName ?? '';
};

const findLabelFromOptionsList = (value, optionsList) => {
  const matchedOption = optionsList.find((option) => String(option.value) === String(value));
  return matchedOption ? matchedOption.responseText : '';
};

const populateSelectedChoiceFromList = (value, rowDetail, _, singleSubmission) => {
  let optionsList = rowDetail['row'] ? rowDetail.row.additionalDetail.optionList : rowDetail;
  const fieldKey = rowDetail['row']['associatedKey'];
  const displayMapping = singleSubmission.elementMetaDataMap[fieldKey];
  if (value) {
    if (typeof value === 'string') {
      if (displayMapping?.[value]) {
        return displayMapping[value];
      }
      return findLabelFromOptionsList(value, optionsList) || value;
    } else if (Array.isArray(value)) {
      const labels = value.map((singleValue) => {
        let label = displayMapping?.[singleValue];
        if (!label) {
          label = findLabelFromOptionsList(singleValue, optionsList);
        }
        return label || singleValue;
      });
      return labels.join(', ');
    }
  }
  return '';
};
const populateWithSliderValue = (value, rowDetail) => {
  let noOfStars =
    rowDetail && rowDetail['row'] ? rowDetail.row.additionalDetail.noOfStars : rowDetail;
  if (value) {
    value = parseInt(value).toString() === parseFloat(value).toString() ? parseInt(value) : value;
    return (
      <div className='d-flex'>
        {Array.from(Array(noOfStars).keys()).map((_, index) => (
          <img
            key={index}
            src={index < value ? starFilledIcon : starHollowIcon}
            style={{ height: '2rem' }}
          />
        ))}
      </div>
    );
  } else return <></>;
};

const populateNumberSlider = (value, rowDetail) => {
  const minRange = Number(rowDetail?.row?.additionalDetail?.minRange ?? 1);
  const maxRange = Number(rowDetail?.row?.additionalDetail?.maxRange ?? 100);

  return (
    <div style={{ width: '150px', marginTop: '20px' }}>
      <NumberRangeField
        name='numberRange'
        value={Number(value)}
        minRange={minRange}
        maxRange={maxRange}
        handleChange={() => {}}
        disabled
        classes='w-100'
        onlyShowValue
      />
    </div>
  );
};
const populateGroup = (groups) => {
  return (
    <>
      {groups && groups.length > 0 ? (
        <div className='d-flex flex-row width-max-content justify-content-center gap-3'>
          {groups.length > 0 && (
            <>
              <div className='width-max-content theme-background-green-3 rounded-pill p-2 px-3'>
                {groups[0].groupName}
              </div>
              {groups.length > 1 && (
                <div className='width-max-content theme-background-green-3 rounded-pill p-2 px-3'>
                  {' '}
                  + {groups.length - 1}
                </div>
              )}
            </>
          )}
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

const populateStatus = (data, callback, translateVal) => {
  const classification =
    data === 'draft'
      ? { class: 'white-2', label: 'text_draft' }
      : data === 'published'
      ? { class: 'green-3', label: 'text_published' }
      : data === 'testing'
      ? { class: 'orange-1', label: 'text_testing' }
      : data === 'started' || data === 'paused'
      ? { class: 'green-3', label: 'text_working_now' }
      : data === 'ended'
      ? { class: 'orange-1', label: 'text_check_out' }
      : data === 'paused'
      ? { class: 'white-2', label: 'text_on_break' }
      : data === 'admin_invite'
      ? { class: 'blue-2', label: 'text_invited' }
      : { class: 'orange-1', label: 'text_request_pending' };

  return (
    <p
      className={`mb-0 theme-button-${classification.class} rounded-full px-3 py-1 theme-size-1 width-max-content`}
    >
      {translateVal(classification.label)}
    </p>
  );
};
const populateOnBreak = (data) => {
  const classification = data === 'paused' ? { class: 'green-1' } : { class: 'grey-1' };

  return (
    <p
      className={`mb-0 theme-button-${classification.class} rounded-full p-1 m-auto theme-size-1 width-max-content`}
    ></p>
  );
};
const populateAccess = (rowData, changeAccess) => {
  let userRole = rowData.userRole;
  let selectedValueItem = adminAccessLevel.filter((singleVal) => singleVal.value === userRole);
  let selectedValueName = userRole;
  if (selectedValueItem.length > 0) {
    selectedValueName = selectedValueItem[0].displayValue;
  }
  return (
    <SelectField
      label={selectedValueName}
      selectedValues={[userRole]}
      handleChange={changeAccess}
      listValues={adminAccessLevel}
      icon={DropDownArrowGreen}
      rowData={rowData}
      isAutoClose={true}
    />
  );
};
const populateStatusSupervisorField = (rowData, changeAccess) => {
  return rowData.entryStatus === 'complete_entry' ? changeAccess(rowData) : null;
};
const countUsers = (data) => {
  return <div className='text-green'>{data.length}</div>;
};
const groupCreatedBy = (data) => {
  return (
    <div className='d-flex gap-2 align-items-center'>
      <Initials users={[{ firstName: data.firstName, lastName: data.lastName }]} />
      {data.firstName}
    </div>
  );
};
const administrativeInitials = (data) => {
  return <Initials users={data.slice(0, 5)} />;
};
const databaseUpdatedBy = (row) => {
  return <span>{row.firstName + ' ' + row.lastName}</span>;
};

const FormAccessLevel = (row, callBack, translateVal) => {
  return (
    <div
      className='w-100 h-100'
      onClick={() => {
        callBack(row);
      }}
    >
      <p className='d-flex gap-3 align-items-center theme-font-inter-medium mb-0'>
        <img src={row.icon} alt='Loading...' />
        {translateVal(row.access)}
      </p>
    </div>
  );
};
const FormGroupsAccessLevel = (row, callBack, translateVal) => {
  return (
    <div
      className='w-100 h-100'
      onClick={() => {
        callBack(row);
      }}
    >
      <span className='rounded-2 px-2 py-1 theme-font-inter-light'>
        {`${
          row.key === 'managerSections'
            ? (row?.sections?.length ?? 0) + ' ' + translateVal('table_header_section_sections')
            : (row?.groups?.length ?? 0) + ' ' + translateVal('text_groups')
        }`}
      </span>
      <div className='mt-1 mb-0'>
        {row.key === 'managerSections'
          ? row?.sections?.map((section, index) => (
              <span key={index} className='theme-text-black-1 theme-size-0_9'>
                {index !== row?.sections.length - 1
                  ? section.sectionTitle + ', '
                  : section.sectionTitle}
              </span>
            ))
          : row?.groups?.map((group, index) => (
              <span key={index} className='theme-text-black-1 theme-size-0_9'>
                {index !== row?.groups.length - 1 ? group.groupName + ', ' : group.groupName}
              </span>
            ))}
      </div>
    </div>
  );
};
const FormUsersAccessLevel = (row, callBack, translateVal) => {
  let sectionUsers;

  if (row.key === 'managerSections') {
    sectionUsers = (row?.sections ?? [])
      .map((section) =>
        (section?.users ?? []).map((user) => ({
          _id: user?.userId?._id,
          firstName: user?.userId?.firstName,
          lastName: user?.userId?.lastName,
        })),
      )
      .flat();
    sectionUsers = _.uniqBy(sectionUsers, '_id');
  }
  return (
    <div
      className='w-100 h-100'
      onClick={() => {
        callBack(row);
      }}
    >
      <span className='rounded-2 px-2 py-1 theme-font-inter-light'>
        {`${
          row.key === 'managerSections' ? sectionUsers?.length ?? 0 : row?.users?.length ?? 0
        } ${translateVal('text_users')}`}
      </span>
      <p className='mt-1 mb-0'>
        {(row.key === 'managerSections' ? sectionUsers : row?.users).map((user, index) => (
          <span key={index} className='theme-text-black-1 theme-size-0_9'>
            {index !== (row.key === 'managerSections' ? sectionUsers : row?.users).length - 1
              ? `${user.firstName} ${user.lastName}, `
              : `${user.firstName} ${user.lastName}`}
          </span>
        ))}
      </p>
    </div>
  );
};
const FormFolderAccessLevel = (row, callBack) => {
  return (
    <div
      className='w-100 h-100'
      onClick={() => {
        callBack(row);
      }}
    >
      <span className='rounded-2 px-2 py-1 theme-font-inter-light'>
        {row?.folders?.length ?? 0} Folders
      </span>
      <p className='mt-1 mb-0'>
        {row?.folders?.map((folder, index) => (
          <span key={index} className='theme-text-black-1 theme-size-0_9'>
            {index !== row.folders.length - 1 ? `${folder.name}, ` : `${folder.name}`}
          </span>
        ))}
      </p>
    </div>
  );
};
const FormActionAccessLevel = (row, callBack) => {
  return (
    <div
      className='w-100 h-100'
      onClick={() => {
        callBack(row);
      }}
    >
      <img src={arrowIcon} alt='Loading...' />
    </div>
  );
};

const populateFormEditIcon = (row, callBack) => {
  return (
    <div
      className='cursor-pointer'
      onClick={() => {
        callBack(row._id);
      }}
    >
      <img src={formEditIcon} alt='Loading...' />
    </div>
  );
};
const populateWebViewIcon = (row, callBack) => {
  return (
    <div
      className='d-flex justify-content-center align-items-center cursor-pointer theme-background-white-5 p-2 rounded-circle'
      onClick={() => {
        if (callBack) callBack(row);
      }}
    >
      <img src={webViewIcon} alt='Web View' className='m-1' />
    </div>
  );
};
const populateSubmissionFlowState = (value, _, translateVal) => {
  const labelClass = `${
    value.flowState === 'user'
      ? 'theme-background-orange-2 theme-text-orange-1'
      : value.flowState === 'section'
      ? 'theme-background-blue-4 theme-text-blue-3'
      : value.flowState === 'end'
      ? 'theme-background-blue-2 theme-text-blue-1'
      : ''
  } width-max-content py-1 px-2 rounded-sm`;

  return value ? (
    <div className='theme-size-0_9'>
      <div>{value.actionTitle ?? ''}</div>
      <div className={labelClass}>
        {translateVal(
          value.flowState === 'user'
            ? 'text_app_user'
            : value.flowState === 'section'
            ? 'text_manager_section'
            : value.flowState === 'end'
            ? 'text_end'
            : '',
        )}
      </div>
    </div>
  ) : (
    ''
  );
};
const populateViewLogs = (row, callBack) => {
  return (
    <div
      className='d-flex justify-content-center align-items-center cursor-pointer theme-background-white-5 p-2 rounded-circle'
      onClick={() => {
        const formSubmissionId = row.form_submission_id;
        if (callBack) callBack(formSubmissionId);
      }}
    >
      <img src={eyeIcon} alt='View Logs' className='m-1' />
    </div>
  );
};
const populateRestrictedView = (restricted) => {
  return (
    <div className='d-flex justify-content-start align-items-center cursor-pointer p-2 rounded-circle'>
      <img
        src={restricted ? eyeOffIcon : eyeIcon}
        alt={`${restricted ? 'Restricted ' : 'Unrestricted '}View`}
        className='m-1'
      />
    </div>
  );
};
const populateEditDeleteView = (viewId, callBack) => {
  return (
    <div className='d-flex justify-content-center align-items-center cursor-pointer p-2 rounded-circle'>
      <img
        src={editGreenIcon}
        alt='Edit View'
        className='m-1'
        onClick={() => {
          if (callBack) callBack(viewId, 'EDIT');
        }}
      />
      <img
        src={trashRedIcon}
        alt='Delete View'
        className='m-1'
        onClick={() => {
          if (callBack) callBack(viewId, 'DELETE');
        }}
      />
    </div>
  );
};
const displayLogMessage = (value, callBack, translateVal) => {
  return (
    <div
      className='position-relative cursor-pointer text-decoration-underline text-nowrap'
      onClick={() => {
        if (callBack) {
          callBack(value);
        }
      }}
    >
      {translateVal('text_view_message')}
    </div>
  );
};

const displayTranslatedMessage = (value, callBack, translateVal) => {
  return (
    <>
      {translateVal(
        value === 'Sent'
          ? 'text_sent'
          : value === 'Queued'
          ? 'text_queued'
          : value === 'Undelivered'
          ? 'text_undelivered'
          : value === 'Delivered'
          ? 'text_delivered'
          : value,
      )}
    </>
  );
};

const populateCommentsIcon = (row, callBack) => {
  return (
    <div
      className='position-relative d-flex justify-content-center align-items-center cursor-pointer theme-background-white-5 p-2 rounded-circle'
      onClick={() => {
        if (callBack) callBack(row);
      }}
    >
      <img src={commentsIcon} alt='View Comments' className='m-1' />
      <span className='message-indicator'></span>
    </div>
  );
};
const populateFormTitle = (row, callBack) => {
  return (
    <div className='d-flex align-items-center gap-2'>
      <img
        src={
          row.formIcon
            ? formTypeIconValues.filter((singleValue) => singleValue.value === row.formIcon)[0]
                .labelImage
            : formNameImage
        }
        alt='Loading...'
      />
      {callBack ? (
        <span className='cursor-pointer' onClick={() => callBack(row)}>
          {row.title}
        </span>
      ) : (
        <span>{row.title}</span>
      )}
    </div>
  );
};
const populateAccessedFormTitle = (row) => {
  return (
    <div className='d-flex align-items-center gap-2'>
      <img
        src={formTypeIconValues.filter((item) => item.value === row.formIcon)[0].labelImage}
        alt='Loading...'
      />
      {row.title}
    </div>
  );
};

const populateCreatorName = (user) => {
  return (
    <span className='text-capitalize'>{user ? user.firstName + ' ' + user.lastName : 'N/A'}</span>
  );
};

const populatePublishedByName = (publishedBy) => {
  return (
    <span className='text-capitalize'>
      {publishedBy ? publishedBy.firstName + ' ' + publishedBy.lastName : ''}
    </span>
  );
};

const populateLastLocation = (data, callBack, translationFunc) => {
  return (
    <div className='d-flex gap-2 align-items-center'>
      <img src={locationIcon} />
      <span>{translationFunc('text_view_on_map')}</span>
    </div>
  );
};

const actionsList = (row, type, actionsCallBack) => {
  return type === 'groups' ? (
    <GroupActions
      row={row}
      actionsCallBack={actionsCallBack}
      isActionButton={true}
      ids={[]}
      actionData={{ groupSegmentId: row.segment_id }}
    />
  ) : type === 'user' || type == 'pending' || type === 'admin' || type === 'user_archived' ? (
    <UserActions
      ids={[{ id: row._id, title: row.firstName + ' ' + row.lastName }]}
      actionData={{
        userId: row._id,
        userName: row.firstName + ' ' + row.lastName,
        status: row.userStatus,
        adminProfile: row.adminProfile ? row.adminProfile : null,
        userRole: row.userRole,
        tags: row.tags,
        notificationUserId: row.user_id, // This is main user id others are company userId
      }}
      isActionButton={true}
      actionsCallBack={actionsCallBack}
      type={'row_' + type}
    />
  ) : type === 'forms' ||
    type === 'archived' ||
    type === 'bookmarks' ||
    type === 'dashboardForms' ? (
    <FormActions
      ids={[{ id: row._id, title: row.title }]}
      isActionButton={true}
      actionsCallBack={actionsCallBack}
      type={'row_' + type}
      actionData={{
        version: row.currentVersion._id,
        designersList: row.designer,
        supervisorsList: row.supervisor,
        creator: row.createdBy,
        versionsList: row.versions,
      }}
    />
  ) : type === 'form_version' ? (
    <VersionActions ids={[{ id: row._id, formId: actionsCallBack }]} />
  ) : type === 'submission_actions' ? (
    <SubmissionActions
      ids={[{ id: row.form_submission_id }]}
      isActionButton={true}
      actionsCallBack={actionsCallBack}
      type={'row_' + type}
    />
  ) : type === 'data_sources' ? (
    <DataSourceActions
      ids={[{ id: row._id, title: row.schemaName }]}
      isActionButton={true}
      actionsCallBack={actionsCallBack}
      type={'row_' + type}
    />
  ) : type === 'data_sources_entries' ? (
    <EntriesActions
      ids={[row.row_datasource_entry_id]}
      actionData={{
        datasourceName: row.row_datasource_name,
        row_datasource_id: row.row_datasource_id,
      }}
      isActionButton={true}
      actionsCallBack={actionsCallBack}
      type={'row_' + type}
    />
  ) : type === 'trash_forms' || type === 'trash_submissions' ? (
    <TrashActions
      ids={[
        type === 'trash_forms'
          ? {
              id: row._id,
              title: row.title,
            }
          : {
              id: row._id,
              title: row.formTitle,
              form_id: row.form?._id,
              form_status: row.form?.formStatus,
            },
      ]}
      isActionButton={true}
      actionsCallBack={actionsCallBack}
      type={'row_' + type}
    />
  ) : (
    ''
  );
};

export const populateProfileTitle = (row, callBack) => {
  return (
    <div onClick={() => callBack(row)} className='d-flex align-items-center gap-3 cursor-pointer'>
      <img src={row.isOwnerProfile ? profileLockIcon : profileEditGreenIcon} alt='Loading...' />
      <span>{row.profileName}</span>
    </div>
  );
};
export const populateSectionManage = (row, callbackFunc) => {
  return (
    <FormButton
      variant='green-1'
      text='button_manage'
      onClick={() => {
        callbackFunc(row);
      }}
    />
  );
};

const populateResponseSetTexts = (responseSet) => {
  const responseSetOptions = JSON.parse(responseSet);
  return (
    <div className='d-flex gap-2'>
      {responseSetOptions.map((option, index) => (
        <label
          key={index}
          className='px-3 py-2 rounded-pill theme-text-white-1'
          style={{ backgroundColor: option.itemColor }}
        >
          {option.responseText}
        </label>
      ))}
      <div></div>
    </div>
  );
};

const populateResponseSetValues = (responseSet) => {
  const responseSetOptions = JSON.parse(responseSet);
  return (
    <div className='d-flex gap-2'>
      <label>{responseSetOptions.map((option) => option.value).join(', ')}</label>
    </div>
  );
};

const populateResponseSetEditDeleteIcon = (row, callBack) => {
  return (
    <div className='d-flex gap-2'>
      {isPermissionAvailable(permissionKeys.field_work_response_set_delete) && (
        <img
          src={trashGreyIcon}
          className='cursor-pointer'
          alt='Loading...'
          onClick={() => callBack(row._id, 'delete')}
        />
      )}
      {isPermissionAvailable(permissionKeys.field_work_response_set_create) && (
        <img
          src={editSquareGreyIcon}
          className='cursor-pointer'
          alt='Loading...'
          onClick={() => callBack(row._id, 'edit')}
        />
      )}
    </div>
  );
};

const populateUserNameInDataSourceFilter = (row, actionCallBack) => {
  return (
    <div className='d-flex gap-2'>
      <img className='cursor-pointer' src={trashGreyIcon} onClick={() => actionCallBack(row._id)} />
      <label>{row.userName}</label>
    </div>
  );
};

const populateCheckField = (row, actionCallBack, filterKey) => {
  const handleChange = (field, value) => {
    if (field === 'all') {
      for (let f in row.filterValuesObj) row.filterValuesObj[f] = value;
      actionCallBack(row.userId, row.filterValuesObj);
    } else {
      row.filterValuesObj[field] = value;
      actionCallBack(row.userId, row.filterValuesObj);
    }
  };
  const isAllTrue = () => Object.values(row.filterValuesObj).every((v) => !!v);

  return (
    <div className='d-flex justify-content-center'>
      <SingleCheckBoxField
        name={filterKey}
        value={filterKey === 'all' ? isAllTrue() : row.filterValuesObj[filterKey] ?? false}
        handleChange={handleChange}
        classes='w-auto'
      />
    </div>
  );
};

export default {
  userInitials,
  populateTags,
  populateGroup,
  populateDate,
  populateTime,
  populateDateTime,
  populateDateTimeForSubmission,
  populateUnixToDate,
  populateUnixToTime,
  populateUnixToDateTime,
  populateTimeAgo,
  populateOnBreak,
  populateSelectedChoiceFromList,
  populateWithSliderValue,
  populateNumberSlider,
  populateCreatorName,
  populateStatus,
  populateAccess,
  populateStatusSupervisorField,
  countUsers,
  groupCreatedBy,
  administrativeInitials,
  actionsList,
  databaseUpdatedBy,
  FormFolderAccessLevel,
  FormGroupsAccessLevel,
  FormUsersAccessLevel,
  FormAccessLevel,
  FormActionAccessLevel,
  populateFormEditIcon,
  populateWebViewIcon,
  populateCommentsIcon,
  populatePublishedByName,
  populateFormTitle,
  populateAccessedFormTitle,
  populateLastLocation,
  populateTotalHours,
  populateKeyFilterName,
  populateProfileTitle,
  populateSectionManage,
  populateResponseSetTexts,
  populateResponseSetValues,
  populateResponseSetEditDeleteIcon,
  populateUserNameInDataSourceFilter,
  populateCheckField,
  populateSubmissionFlowState,
  populateViewLogs,
  populateRestrictedView,
  populateEditDeleteView,
  displayLogMessage,
  displayTranslatedMessage,
};
