import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import arrowDownIcon from 'assets/images/icons/dropdown-arrow-grey.svg';
import monitorIcon from 'assets/images/icons/monitor.svg';
import pdfPageIcon from 'assets/images/icons/pdf-page.svg';
import AlertModal from 'components/common-components/AlertModal';
import FormButton from 'components/form-components/FormButton';
import FormLoadingButton from 'components/form-components/FormLoadingButton';
import SelectField from 'components/form-components/SelectField';
import TabSelectionField from 'components/form-components/TabSelectionField';
import TextField from 'components/form-components/TextField';
import ThumbnailSelectionField from './ThumbnailSelectionField';
import ToggleSwitch from 'components/form-components/ToggleSwitch';
import { CallAPI } from 'actions/General';

const ReportLayoutSetting = ({ updateTab, formDetails, getFormDetail }) => {
  const { t } = useTranslation();

  const textDirectionValues = [
    { value: 'left_to_right', displayValue: 'select_left_to_right' },
    { value: 'right_to_left', displayValue: 'select_right_to_left' },
  ];

  const textDirectionValuesMap = Object.fromEntries(
    textDirectionValues.map((v) => [v.value, v.displayValue]),
  );

  const photoResolutionOptionList = [
    { value: 'standard', displayValue: 'select_standard' },
    { value: 'high', displayValue: 'select_high' },
  ];

  const reportTypeOptionList = [
    { value: 'test_data', displayValue: 'select_test_data' },
    { value: 'real_data', displayValue: 'select_real_data' },
  ];

  const [templateViewType, setTemplateViewType] = useState('');
  const [loading, setLoading] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [values, setValues] = useState({
    textDirection: textDirectionValues[0].value,
    showLogo: false,
    showQRCode: false,
    pageSize: 'A4',
    thumbSize: 'small',
    photoResolution: photoResolutionOptionList[0].value,
    mediaSummary: false,
    showWorkflowSummary: false,
    showUnansweredQuestions: false,
    showSupervisorFields: false,
    enableFooter: false,
    footerText: '',
    sampleReportForType: reportTypeOptionList[0].value,
  });

  const handleChange = (field, value) => {
    setValues({
      ...values,
      [field]: value,
      ...(field === 'enableFooter' && !value ? { footerText: '' } : {}),
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const result = await CallAPI(
        'SAVE_FORM',
        { formId: formDetails?._id ? formDetails._id : '', layoutSettings: values },
        null,
        setLoading,
        null,
        null,
      );

      if (result.status) {
        setShowSuccessAlert(true);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    setValues({
      textDirection: formDetails?.layoutSettings?.textDirection ?? textDirectionValues[0].value,
      showLogo: formDetails?.layoutSettings?.showLogo ?? false,
      showQRCode: formDetails?.layoutSettings?.showQRCode ?? false,
      pageSize: 'A4',
      thumbSize: formDetails?.layoutSettings?.thumbSize ?? 'small',
      photoResolution:
        formDetails?.layoutSettings?.photoResolution ?? photoResolutionOptionList[0].value,
      mediaSummary: formDetails?.layoutSettings?.mediaSummary ?? false,
      showWorkflowSummary: formDetails?.layoutSettings?.showWorkflowSummary ?? false,
      showUnansweredQuestions: formDetails?.layoutSettings?.showUnansweredQuestions ?? false,
      showSupervisorFields: formDetails?.layoutSettings?.showSupervisorFields ?? false,
      enableFooter: formDetails?.layoutSettings?.enableFooter ?? false,
      footerText: formDetails?.layoutSettings?.footerText ?? '',
      sampleReportForType:
        formDetails?.layoutSettings?.sampleReportForType ?? reportTypeOptionList[0].value,
    });
  }, [formDetails]);
  const openSubmissionTemplate = async (type) => {
    setTemplateViewType(type === templateViewType ? '' : type);
    const result = await CallAPI('GET_PREVIEW_ID', {
      previewOf: 'form',
      formId: formDetails._id,
    });

    if (result.status) {
      const dataType = values.sampleReportForType === 'real_data' ? 'real' : 'test';
      // eslint-disable-next-line no-undef
      const reportLink = `${process.env.REACT_APP_ADMIN_PANEL_URL}/report/${
        type === 'web' ? 'web_link' : 'pdf_preview'
      }/${dataType}/${result.data._id}`;
      window.open(reportLink, '_blank');
    }
    setTemplateViewType('');
  };
  return (
    <div className='form-report-layout-setting'>
      <AlertModal
        show={showSuccessAlert}
        showCloseButton={true}
        closeModal={() => setShowSuccessAlert(false)}
        title='alert_success'
        message={'alert_layout_updated'}
        variant='success'
        actionButtons={[
          {
            text: t('button_ok'),
            variant: 'green-1',
            onClick: () => {
              setShowSuccessAlert(false);
              getFormDetail(formDetails._id);
            },
          },
        ]}
      />
      <div className='d-flex flex-column gap-4 flex-grow-1'>
        <SelectField
          name='textDirection'
          handleChange={handleChange}
          listValues={textDirectionValues}
          placeholder='text_direction'
          label={textDirectionValuesMap[values.textDirection] ?? 'select_select'}
          icon={arrowDownIcon}
          classes='w-auto'
          variant='white-1'
          menuClasses='w-100'
          isAutoClose={true}
          isHorizontal={true}
        />
        <div className='d-flex align-items-center'>
          <label className='min-width-176'>{t('text_show_logo')}</label>
          <ToggleSwitch name='showLogo' value={values.showLogo} handleChange={handleChange} />
        </div>
        <div className='d-flex align-items-center'>
          <label className='min-width-176'>{t('text_show_qr_code')}</label>
          <ToggleSwitch name='showQRCode' value={values.showQRCode} handleChange={handleChange} />
        </div>
        <div>
          <label className='min-width-176'>{t('text_page_size')}</label>
          <label className='theme-font-inter-medium theme-text-black-1 theme-size-1_2'>A4</label>
        </div>
        <div className='d-flex'>
          <label className='min-width-176'>{t('text_thumbnail_size')}</label>
          <ThumbnailSelectionField
            name='thumbSize'
            value={values.thumbSize}
            handleChange={handleChange}
          />
        </div>
        <div className='d-flex align-items-center'>
          <label className='min-width-176'>{t('text_photo_resolution')}</label>
          <TabSelectionField
            name='photoResolution'
            value={values.photoResolution}
            optionList={photoResolutionOptionList}
            handleChange={handleChange}
          />
        </div>
        <div className='d-flex align-items-center'>
          <label className='min-width-176'>{t('text_media_summary')}</label>
          <ToggleSwitch
            name='mediaSummary'
            value={values.mediaSummary}
            handleChange={handleChange}
          />
        </div>
        <div className='d-flex align-items-center'>
          <label className='min-width-176'>{t('text_workflow_summary')}</label>
          <ToggleSwitch
            name='showWorkflowSummary'
            value={values.showWorkflowSummary}
            handleChange={handleChange}
          />
        </div>
        <div className='d-flex align-items-center'>
          <label className='min-width-176'>{t('text_show_unanswered_qs')}</label>
          <ToggleSwitch
            name='showUnansweredQuestions'
            value={values.showUnansweredQuestions}
            handleChange={handleChange}
          />
        </div>
        <div className='d-flex align-items-center'>
          <label className='min-width-176'>{t('text_show_manager_fields')}</label>
          <ToggleSwitch
            name='showSupervisorFields'
            value={values.showSupervisorFields}
            handleChange={handleChange}
          />
        </div>
        <div className='d-flex align-items-center'>
          <label className='min-width-176'>{t('text_footer')}</label>
          <ToggleSwitch
            name='enableFooter'
            value={values.enableFooter}
            handleChange={handleChange}
          />
        </div>
        {values.enableFooter && (
          <div className='d-flex align-items-center'>
            <label className='min-width-176'></label>
            <TextField
              name='footerText'
              value={values.footerText}
              placeholder='text_footer_text'
              handleChange={handleChange}
              shrink
            />
          </div>
        )}
        <div className='w-100 d-flex justify-content-end gap-3'>
          <FormButton text='button_cancel' variant='white-1' onClick={() => updateTab(0)} />
          <FormLoadingButton
            text='button_save'
            variant='green-1'
            loading={loading}
            onClick={onSubmit}
          />
        </div>
      </div>
      <div className='preview-options-panel'>
        <div>
          <label className='mb-2'>{t('text_show_sample_report_for')}</label>
          <TabSelectionField
            name='sampleReportForType'
            value={values.sampleReportForType}
            optionList={reportTypeOptionList}
            handleChange={handleChange}
          />
        </div>
        <div
          className={`d-flex flex-column gap-2 justify-content-center align-items-center cursor-pointer ${
            templateViewType === 'web' ? 'active' : ''
          }`}
          onClick={() => openSubmissionTemplate('web')}
        >
          <div className='d-flex gap-2 align-items-center'>
            <img src={monitorIcon} />
            <label>{t('text_web_preview')}</label>
          </div>
        </div>
        <div
          className={`d-flex flex-column gap-2 justify-content-center align-items-center cursor-pointer ${
            templateViewType === 'pdf' ? 'active' : ''
          }`}
          onClick={() => openSubmissionTemplate('pdf')}
        >
          <div className='d-flex gap-2 align-items-center'>
            <img src={pdfPageIcon} />
            <label>{t('text_pdf_preview')}</label>
          </div>
        </div>
      </div>
    </div>
  );
};

ReportLayoutSetting.propTypes = {
  getFormDetail: PropTypes.func.isRequired,
  updateTab: PropTypes.func.isRequired,
  formDetails: PropTypes.object,
};

export default ReportLayoutSetting;
