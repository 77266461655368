import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import AlertModal from 'components/common-components/AlertModal';
import AttachmentsOverviewContent from 'pages/submissions/AttachmentsOverviewContent';
import SingleCheckBoxField from 'components/form-components/SingleCheckBoxField';
import submissionAttachment from 'assets/images/icons/submission-attachment.svg';
import TableUtils from 'components/table-component/TableUtils';

const TableBody = ({
  data,
  tableFields,
  props,
  loader,
  // isActionsEnabled,
  onSelection,
  isSelectionEnabled,
  selectionKey,
  selectedRows,
  classes,
  initialColsWidthMap,
  widthOfCheckboxCol,
  applyTablePartitionSideBorder,
  applyManagerSecPartitionBorder,
  htmlDirection,
}) => {
  let {
    // actionsType,
    actionsCallBack,
    submissionsMediaObject,
    translateValueFunc,
  } = {
    ...props,
  };
  const { t } = useTranslation();
  const [showMediaModal, setShowMediaModal] = useState(false);
  const [mediaAndNotes, setMediaAndNotes] = useState({
    media: [],
    note: '',
    showTimeStamp: false,
    mediaTimeStampString: {},
    dialogType: '',
  });

  const showMedia = (mediaNotes, formVersionId, showTimeStamp, dialogType) => {
    const mediaTimeStampString = mediaNotes.mediaTimeStampString
      ? JSON.parse(mediaNotes.mediaTimeStampString)
      : {};
    setShowMediaModal(true);
    if (dialogType === 'submission') {
      setMediaAndNotes({
        ...mediaNotes,
        media: [...mediaNotes.media].map((media) => ({ ...media })),
        showTimeStamp,
        mediaTimeStampString,
        dialogType,
      });
    } else {
      setMediaAndNotes({
        ...mediaNotes,
        showTimeStamp: false,
        mediaTimeStampString: {},
        dialogType,
      });
    }
  };
  const closeModal = () => {
    setShowMediaModal(false);
    setMediaAndNotes({ media: [], note: '' });
  };

  return (
    <Fragment>
      <tbody className={`${classes?.adjustTableBody}`}>
        {data &&
          data.map((singleRecord, index) => {
            return (
              <tr key={index} className='table-checkbox'>
                {isSelectionEnabled ? (
                  <td
                    className='sticky-cell'
                    style={{
                      width: `${widthOfCheckboxCol}px`,
                      ...{
                        [htmlDirection ? 'right' : 'left']: '0px',
                      },
                    }}
                  >
                    <SingleCheckBoxField
                      name={selectionKey.split('.').reduce((acc, curr) => acc[curr], singleRecord)}
                      value={
                        selectedRows
                          .map((e) => (e.id ? e.id : e))
                          .indexOf(
                            selectionKey.split('.').reduce((acc, curr) => acc[curr], singleRecord),
                          ) > -1
                          ? true
                          : false
                      }
                      disabled={singleRecord.entryStatus !== 'complete_entry'}
                      handleChange={onSelection}
                      text={<></>}
                      classes=''
                    />
                  </td>
                ) : null}
                {tableFields.map((singleHeaderField, index1) => {
                  let value = singleHeaderField.associatedKey;
                  let callbackFunc = singleHeaderField.callbackFunc
                    ? props[singleHeaderField?.callbackFunc]
                    : singleHeaderField.sendRowWithKeyValue
                    ? { row: singleHeaderField, key: singleHeaderField.name }
                    : null;
                  return singleHeaderField.isSelected ? (
                    <td
                      key={`in1${index1}`}
                      onClick={() => {
                        if (
                          singleHeaderField?.tableType === 'supervisorFields' &&
                          actionsCallBack &&
                          singleRecord.entryStatus === 'complete_entry' &&
                          singleHeaderField.type !== 'status'
                        ) {
                          actionsCallBack(singleRecord, singleHeaderField);
                        }
                      }}
                      className={`nowrap ${
                        initialColsWidthMap[singleHeaderField?.name]
                          ? 'sticky-cell'
                          : 'non-sticky-cell'
                      } ${
                        singleHeaderField?.tableType === 'supervisorFields' ? 'cursor-pointer' : ''
                      } ${applyTablePartitionSideBorder(
                        singleHeaderField,
                      )} ${applyManagerSecPartitionBorder(singleHeaderField)}`}
                      style={{
                        width: initialColsWidthMap[singleHeaderField.name]?.width
                          ? `${initialColsWidthMap[singleHeaderField.name]?.width}px`
                          : '150px',
                        ...(initialColsWidthMap[singleHeaderField.name]?.left && {
                          [htmlDirection ? 'right' : 'left']: `${
                            initialColsWidthMap[singleHeaderField.name].left
                          }px`,
                        }),
                      }}
                    >
                      <div
                        className={`d-flex justify-content-between gap-2 main-div ${
                          singleHeaderField?.tableType === 'supervisorFields' ? 'show-edit' : ''
                        }`}
                      >
                        {singleHeaderField.populateFunc
                          ? TableUtils[singleHeaderField.populateFunc](
                              value
                                ? value.split('.').reduce((acc, curr) => acc[curr], singleRecord)
                                : singleRecord,
                              callbackFunc,
                              singleHeaderField.translateVal ? translateValueFunc : null,
                              singleRecord,
                            )
                          : value && singleRecord
                          ? value.split('.').reduce((acc, curr) => acc[curr], singleRecord)
                          : ''}
                      </div>
                      {singleHeaderField?.tableType === 'basicFields' &&
                      submissionsMediaObject &&
                      submissionsMediaObject[singleRecord.form_submission_id] &&
                      submissionsMediaObject[singleRecord.form_submission_id][
                        singleHeaderField.associatedKey
                      ] &&
                      (submissionsMediaObject[singleRecord.form_submission_id][
                        singleHeaderField.associatedKey
                      ]['media'].length > 0 ||
                        submissionsMediaObject[singleRecord.form_submission_id][
                          singleHeaderField.associatedKey
                        ]['note'] !== '') ? (
                        <div
                          style={{
                            position: 'absolute',
                            top: '0',
                            bottom: '0',
                            [htmlDirection ? 'left' : 'right']: '0',
                            background: '#ffffff',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <img
                            className='ps-2 pe-3 cursor-pointer'
                            src={submissionAttachment}
                            onClick={() =>
                              showMedia(
                                submissionsMediaObject[singleRecord.form_submission_id][
                                  singleHeaderField.associatedKey
                                ],
                                singleRecord.form_version_id,
                                singleHeaderField.showMediaTimeStamp ?? false,
                                'submission',
                              )
                            }
                          />
                        </div>
                      ) : null}
                    </td>
                  ) : null;
                })}
                {/* {isActionsEnabled && (
                  <td className='d-flex justify-content-end align-items-center'>
                    {TableUtils['actionsList'](singleRecord, actionsType, singleHeaderField?.tableType === "supervisorFields" ? actionsCallBack : null)}
                  </td>
                )} */}
              </tr>
            );
          })}
        {!loader && data && data.length === 0 && (
          <tr>
            <td colSpan={50} className='no-record-found'>
              {t('text_no_records_found')}
            </td>
          </tr>
        )}
      </tbody>
      <AlertModal
        show={showMediaModal}
        closeModal={closeModal}
        showCloseButton
        size='lg'
        actionButtons={[]}
      >
        {showMediaModal ? <AttachmentsOverviewContent mediaAndNotes={mediaAndNotes} /> : <></>}
      </AlertModal>
    </Fragment>
  );
};

TableBody.propTypes = {
  selectedRows: PropTypes.array,
  data: PropTypes.array.isRequired,
  tableFields: PropTypes.array.isRequired,
  props: PropTypes.object.isRequired,
  loader: PropTypes.bool,
  isActionsEnabled: PropTypes.bool.isRequired,
  isSelectionEnabled: PropTypes.bool,
  selectionKey: PropTypes.string,
  onSelection: PropTypes.func,
  classes: PropTypes.object,
  initialColsWidthMap: PropTypes.object,
  widthOfCheckboxCol: PropTypes.number,
  applyTablePartitionSideBorder: PropTypes.func,
  applyManagerSecPartitionBorder: PropTypes.func,
  htmlDirection: PropTypes.bool,
};

export default TableBody;
