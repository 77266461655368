import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { CallAPI } from 'actions/General';
import editIcon from 'assets/images/icons/edit.svg';
import messageSquareGreyIcon from 'assets/images/icons/message-square-grey.svg';
import AlertModal from 'components/common-components/AlertModal';
import Loader from 'components/common-components/Loader';
import MediaPreviewModal from './MediaPreviewModal';
import ReportActions from './ReportActions';
import sectionToggleHook from './sectionToggleHook';

const ReportModal = ({ webReportInfo, setWebReportInfo, editManagerSection, addComments }) => {
  const [loader, setLoader] = useState(false);
  const [displayedHtml, setDisplayedHtml] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [previewId, setPreviewId] = useState('');

  // Custom hooks
  sectionToggleHook(displayedHtml);

  const closeModal = () => setWebReportInfo(null);

  const getViewDetail = async (previewId, dataType, actionType) => {
    let result = await CallAPI(
      'GET_PREVIEW_DETAIL',
      { previewId, dataOf: dataType, action: actionType },
      null,
      setLoader,
      null,
      null,
    );
    if (result.status) {
      result = await CallAPI(
        'EXTERNAL_POST_API',
        { ...result.data.data },
        result.data.url,
        setLoader,
        null,
        null,
      );
      setDisplayedHtml(result.data.html);
    } else {
      setErrorMessage(result.error);
    }
  };

  const getReportPreviewId = async () => {
    const payload = {
      previewOf: 'submission',
      formId: webReportInfo.formId,
      submissionId: webReportInfo.submissionId,
    };
    const result = await CallAPI('GET_PREVIEW_ID', payload, null, setLoader);
    if (result.status) {
      const dataType = webReportInfo.dataType;
      getViewDetail(result.data._id, dataType, 'web_link');
      setPreviewId(result.data._id);
    }
  };

  const clickEditAction = () => {
    if (webReportInfo.row) editManagerSection(webReportInfo.row);
  };

  const clickCommentAction = () => {
    if (webReportInfo.row) addComments(webReportInfo.row);
  };

  useEffect(() => {
    if (webReportInfo) {
      getReportPreviewId();
    }
  }, []);

  return (
    <AlertModal
      show={!!webReportInfo}
      actionButtons={[]}
      showCloseButton
      closeModal={closeModal}
      noPadding
      size='xl'
    >
      <div className='w-100'>
        {loader && <Loader color='green-1' type='text-center my-3' />}
        {displayedHtml && (
          <>
            <div dangerouslySetInnerHTML={{ __html: displayedHtml }}></div>
            <ReportActions
              dataType={webReportInfo.dataType}
              previewId={previewId}
              htmlContent={displayedHtml}
            />
            <div className='submission-report-modal-actions-icons'>
              <div className='d-flex gap-2'>
                {webReportInfo.allowEditManagerSection && (
                  <div
                    className='theme-background-green-1 p-3 rounded-circle cursor-pointer'
                    onClick={() => clickEditAction()}
                  >
                    <img src={editIcon} />
                  </div>
                )}
                <div
                  className='theme-background-green-1 p-3 rounded-circle cursor-pointer'
                  onClick={() => clickCommentAction()}
                >
                  <img className='svg-white' src={messageSquareGreyIcon} />
                </div>
              </div>
            </div>
            <MediaPreviewModal displayedHtml={displayedHtml} />
          </>
        )}
        {errorMessage && (
          <AlertModal
            show={true}
            title='Invalid Link'
            message="Link Doesn't Exist"
            actionButtons={[]}
          />
        )}
      </div>
    </AlertModal>
  );
};

ReportModal.propTypes = {
  webReportInfo: PropTypes.object,
  setWebReportInfo: PropTypes.func.isRequired,
  editManagerSection: PropTypes.func.isRequired,
  addComments: PropTypes.func.isRequired,
};

export default ReportModal;
