import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import chevronGreyIcon from 'assets/images/icons/chevron-grey.svg';
import AlertModal from 'components/common-components/AlertModal';
import DateTimeField from 'components/form-components/DateTimeField';
import FormLoadingButton from 'components/form-components/FormLoadingButton';
import NestedSelect from 'components/form-components/NestedSelect';
import NumberField from 'components/form-components/NumberField';
import RatingStarField from 'components/form-components/RatingStarField';
import SignatureField from 'components/form-components/SignatureField';
import SingleCheckBoxField from 'components/form-components/SingleCheckBoxField';
import TextField from 'components/form-components/TextField';

const AddSubmission = ({
  supervisorList,
  handleClose,
  onSubmitForm,
  values,
  loading,
  isNestedModal,
}) => {
  const { t } = useTranslation();
  const [formValues, setFormValues] = useState(null);
  const [notifyViaSms, setNotifyViaSms] = useState({});

  useEffect(() => {
    setFormValues(values);
  }, [values]);

  const handleNotifySmsHandleChange = (field, value) => {
    setNotifyViaSms({ ...notifyViaSms, [field]: value });
  };

  const getFieldOptionList = (field) => {
    return (field.additionalDetail?.optionList ?? []).map((option) => ({
      displayValue: option.responseText,
      value: option.value,
    }));
  };

  const getSelectedOptionLabel = (field) => {
    const selectedOption = (field.additionalDetail?.optionList ?? []).find(
      (option) => option.value === formValues[field.name],
    );
    return selectedOption?.responseText ?? 'select_select';
  };

  const handleValuesChange = (field, value) => {
    setFormValues({ ...formValues, [field]: value });
  };

  const submit = async () => {
    let sendNotificationToInspector = false;

    const formData = [];
    Object.entries(formValues).forEach(([key, value]) => {
      const foundSupervisorField = supervisorList.find((field) => field.name === key);
      if (foundSupervisorField) {
        let newObject = {
          elementGlobalId: key,
          valueField: value.toString(),
          media: [],
          arrayField: [],
        };
        if (foundSupervisorField.type === 'signature') {
          newObject.type = 'signature';
        } else if (foundSupervisorField.type === 'response') {
          const selectedValueOption = foundSupervisorField.additionalDetail.optionList.find(
            (option) => option.value === value && value !== '',
          );
          if (selectedValueOption)
            newObject.elementMetaData = JSON.stringify({
              displayMap: { [value]: selectedValueOption.responseText },
            });
        }
        if (isShowField(foundSupervisorField)) {
          formData.push(newObject);
          if (!sendNotificationToInspector && notifyViaSms[`notify_${key}`])
            sendNotificationToInspector = true;
        }
      }
    });

    onSubmitForm(
      formData,
      values.submissionId,
      values.formVersionId,
      supervisorList[0].managerSectionGlobalId ?? null,
      values.mediaCustomFolderId,
      sendNotificationToInspector,
    );
  };

  const isShowField = (field) => {
    const { fieldIdentitiesObj } = field;
    const { parentId, check, value } = fieldIdentitiesObj?.[field.name] || {};
    if (!parentId) return true;
    const parentValue = formValues[parentId];
    if (
      parentValue?.length > 0 &&
      ((check === 'is' && value === parentValue) || (check === 'is_not' && value !== parentValue))
    ) {
      const grandParentId = fieldIdentitiesObj[parentId]?.parentId;
      if (grandParentId) {
        const grandParentCurrentValue = formValues[grandParentId];
        if (grandParentCurrentValue?.length > 0) {
          const grandParentCheck = fieldIdentitiesObj[parentId]?.check;
          const grandParentValue = fieldIdentitiesObj[parentId]?.value;
          if (
            (grandParentCheck === 'is' && grandParentValue === grandParentCurrentValue) ||
            (grandParentCheck === 'is_not' && grandParentValue !== grandParentCurrentValue)
          ) {
            return true;
          }
        }
        return false;
      }
      return true;
    }
    return false;
  };

  return (
    <AlertModal
      show={true}
      closeModal={handleClose}
      showCloseButton
      actionButtons={[]}
      backdropClassName={isNestedModal ? 'backdrop-overlap' : ''}
    >
      <div className='add-submission-modal'>
        <label className='w-100 theme-text-black-1 text-center theme-font-inter-semi-bold theme-size-1_5 mb-2'>
          {t('text_update_submission')}
        </label>
        {values.isAnyFieldMissing && (
          <label className='w-100 theme-text-red-1 text-center mb-2'>
            {t('text_field_not_contains_in_version')}
          </label>
        )}
        {formValues && (
          <div className='d-flex flex-column gap-4 align-items-center'>
            <div className='fields-list-section'>
              {supervisorList.map((singleField, index) => {
                return isShowField(singleField) ? (
                  <Fragment key={index}>
                    {singleField.type === 'response' ? (
                      <NestedSelect
                        options={getFieldOptionList(singleField)}
                        name={singleField.name}
                        displayName={singleField.displayName}
                        handleChange={handleValuesChange}
                        toggleClasses='p-2 border-grey-1-h-1'
                        toggleComponent={
                          <>
                            <label className='cursor-pointer'>
                              {getSelectedOptionLabel(singleField) ?? 'text_flow_status'}
                            </label>
                            <img src={chevronGreyIcon} className='rotate-180' />
                          </>
                        }
                      />
                    ) : singleField.type === 'dateAndTime' ||
                      singleField.type === 'date' ||
                      singleField.type === 'time' ? (
                      <DateTimeField
                        value={
                          formValues[singleField.name]
                            ? formValues[singleField.name].toString()
                            : ''
                        }
                        name={singleField.name}
                        placeholder=''
                        type={singleField.type}
                        displayName={singleField.displayName}
                        classes='min-width-160'
                        handleChange={handleValuesChange}
                        unixTimeStamp
                      />
                    ) : singleField.type === 'number' ? (
                      <NumberField
                        value={
                          formValues[singleField.name]
                            ? parseInt(formValues[singleField.name])
                            : undefined
                        }
                        name={singleField.name}
                        displayName={singleField.displayName}
                        classes='min-width-160'
                        handleChange={handleValuesChange}
                      />
                    ) : singleField.type === 'ratingSlider' ? (
                      <RatingStarField
                        value={parseInt(formValues[singleField.name])}
                        displayName={singleField.displayName}
                        numOfStars={singleField.additionalDetail.noOfStars}
                        handleChange={(value) => handleValuesChange(singleField.name, value)}
                      />
                    ) : singleField.type === 'signature' ? (
                      <SignatureField
                        name={singleField.name}
                        displayName={singleField.displayName}
                        onChange={(value) => handleValuesChange(singleField.name, value)}
                      />
                    ) : (
                      <Fragment>
                        <TextField
                          value={formValues[singleField.name]}
                          name={singleField.name}
                          displayName={singleField.displayName}
                          placeholder={singleField.displayName}
                          classes='mt-1'
                          handleChange={handleValuesChange}
                        />
                        <SingleCheckBoxField
                          name={`notify_${singleField.name}`}
                          value={notifyViaSms[`notify_${singleField.name}`] ?? false}
                          handleChange={handleNotifySmsHandleChange}
                          text={<span>{t('form_field_notify_via_sms')}</span>}
                        />
                      </Fragment>
                    )}
                  </Fragment>
                ) : null;
              })}
            </div>

            <FormLoadingButton
              onClick={submit}
              loading={loading}
              text='button_submit'
              variant='green-1'
              disabled={supervisorList.length === 0}
            />
          </div>
        )}
      </div>
    </AlertModal>
  );
};

AddSubmission.propTypes = {
  handleClose: PropTypes.func,
  onSubmitForm: PropTypes.func.isRequired,
  supervisorList: PropTypes.array.isRequired,
  values: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  isNestedModal: PropTypes.bool,
};

export default AddSubmission;
