import { useEffect } from 'react';

const sectionToggleHook = (html) => {
  useEffect(() => {
    if (html) {
      const allSectionElements = document.getElementsByClassName('section');
      for (let i = 0; i < allSectionElements.length; i++) {
        const element = allSectionElements[i];
        element.addEventListener('click', () => {
          if (element.classList.contains('closed')) {
            element.classList.remove('closed');

            let nextSibling = element.nextElementSibling;
            while (nextSibling && nextSibling.classList.contains('section-element')) {
              nextSibling.style.display = 'block';
              nextSibling = nextSibling.nextElementSibling;
            }
          } else {
            element.classList.add('closed');
            let nextSibling = element.nextElementSibling;
            while (nextSibling && nextSibling.classList.contains('section-element')) {
              nextSibling.style.display = 'none';
              nextSibling = nextSibling.nextElementSibling;
            }
          }
        });
      }
    }
  }, [html]);
};

export default sectionToggleHook;
