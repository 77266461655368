import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import editSquareGreyIcon from 'assets/images/icons/edit-square-grey.svg';

const ShowViewListModal = ({ viewData, setShowViewModal, currentView, setCurrentView }) => {
  const { t } = useTranslation();
  const applyView = (viewIndex) => {
    setCurrentView(viewData[viewIndex]._id);
    setShowViewModal((prevState) => ({ ...prevState, showViewListModal: false }));
  };
  return (
    <div className='show-view-list-modal'>
      <div className='py-2 px-3'>
        {viewData.map(({ name, _id }, index) => {
          return (
            <label
              key={index}
              className={`view-name-label ${
                currentView === _id ? 'theme-text-green-1' : 'theme-text-black-1'
              } theme-font-jakartaSans-medium d-block cursor-pointer`}
              onClick={() => applyView(index)}
            >
              {name}
            </label>
          );
        })}
        <div
          className='d-flex gap-2 justify-content-start'
          onClick={() =>
            setShowViewModal((prevState) => ({
              ...prevState,
              showViewListModal: false,
              showManageViewModal: true,
            }))
          }
        >
          <img src={editSquareGreyIcon} className='cursor-pointer' />
          <label className={`theme-text-green-1 cursor-pointer`}>{t('text_manage_views')}</label>
        </div>
      </div>
    </div>
  );
};

ShowViewListModal.propTypes = {
  viewData: PropTypes.array.isRequired,
  setShowViewModal: PropTypes.func.isRequired,
  currentView: PropTypes.string,
  setCurrentView: PropTypes.func.isRequired,
};
export default ShowViewListModal;
