import React, { useEffect, useState } from 'react';
import { CallAPI } from 'actions/General';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AlertModal from 'components/common-components/AlertModal';
import Loader from 'components/common-components/Loader';
import MediaPreviewModal from './MediaPreviewModal';
import ReportActions from './ReportActions';
import sectionToggleHook from './sectionToggleHook';

function Report() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const params = useParams();
  const [loader, setLoader] = useState(false);
  const [displayedHtml, setDisplayedHtml] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');

  // Custom hooks
  sectionToggleHook(displayedHtml);

  const getViewDetail = async () => {
    let result = await CallAPI(
      'GET_PREVIEW_DETAIL',
      { previewId: params.previewId, dataOf: params.dataType, action: params.actionType },
      null,
      setLoader,
      null,
      null,
    );
    if (result.status) {
      result = await CallAPI(
        'EXTERNAL_POST_API',
        { ...result.data.data },
        result.data.url,
        setLoader,
        null,
        null,
      );
      if (params.actionType === 'web_link') setDisplayedHtml(result.data.html);
      else if (params.actionType === 'pdf_preview') getPdfLink(result.data.url, result.data.data);
    } else {
      setErrorMessage(result.error);
    }
  };
  const getPdfLink = async (url, data) => {
    console.log('data, url,', data, url);
    let result = await CallAPI('EXTERNAL_POST_API', data, url, setLoader, null, null);
    console.log('result', result);
    if (result.status) {
      window.open(result.data.url, '_self');
    } else {
      setErrorMessage(result.error);
    }
  };

  useEffect(() => {
    if (!loader) getViewDetail();
  }, [params]);

  return (
    <div>
      {loader && <Loader color='green-1' type='modal-spinner' />}
      <div className='d-none' id='supportiveElement'></div>
      {displayedHtml && (
        <>
          <div dangerouslySetInnerHTML={{ __html: displayedHtml }}></div>
          {params && (
            <ReportActions
              dataType={params.dataType}
              previewId={params.previewId}
              htmlContent={displayedHtml}
            />
          )}
          <MediaPreviewModal displayedHtml={displayedHtml} />
        </>
      )}
      {errorMessage && (
        <AlertModal
          show={true}
          title='Invalid Link'
          message="Link Doesn't Exist"
          actionButtons={[
            {
              text: t('button_ok'),
              variant: 'green-1',
              loading: loader,
              onClick: () => navigate('/login'),
            },
          ]}
        />
      )}
    </div>
  );
}

export default Report;
