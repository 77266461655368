import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import SingleCheckBoxField from 'components/form-components/SingleCheckBoxField';

const NestedQuestionsCheckBoxesList = ({
  groupKey,
  groupedArray,
  values,
  setValues,
  level = 0,
}) => {
  const { t } = useTranslation();

  const handleCheckBoxesChange = (name) => {
    setValues((prev) => {
      const updatedFields = { ...prev.selectedFields };
      const updatedGroups = { ...prev.selectedGroups };

      if (updatedFields[name]) {
        delete updatedFields[name];
      } else {
        updatedFields[name] = true;
      }

      // Enable and Disable Select All button
      let allSelected = true;
      const filteredArray =
        groupKey === 'user'
          ? groupedArray.filter((obj) => obj.tableType === 'basicFields')
          : groupedArray;

      for (const { associatedKey } of filteredArray) {
        if (!updatedFields[associatedKey]) {
          allSelected = false;
          break;
        }
      }

      if (allSelected) {
        updatedGroups[groupKey] = true;
      } else {
        delete updatedGroups[groupKey];
      }

      return { ...prev, selectedFields: updatedFields, selectedGroups: updatedGroups };
    });
  };

  return (
    <div className={`mx-${level * 2}`}>
      {groupedArray.map((question, index) => {
        const { name, displayName } = question;
        return (
          <div key={index}>
            <SingleCheckBoxField
              key={name}
              name={name}
              value={values.selectedFields?.[name] || false}
              handleChange={() => handleCheckBoxesChange(name)}
              text={<span>{t(displayName)}</span>}
              disabled={question.tableType === 'user'}
              classes='mb-2'
            />
            {/* Recursively render sub-questions */}
            {/* {question.subQuestions?.length > 0 && (
              <NestedQuestionsCheckBoxesList 
                key={associatedKey}
                groupedArray={subQuestions} 
                values={values} 
                setValues={setValues}
                level={level + 1} 
              />
            )} */}
          </div>
        );
      })}
    </div>
  );
};

NestedQuestionsCheckBoxesList.propTypes = {
  groupKey: PropTypes.string.isRequired,
  groupedArray: PropTypes.array.isRequired,
  values: PropTypes.object.isRequired,
  setValues: PropTypes.func.isRequired,
  level: PropTypes.number,
};
export default NestedQuestionsCheckBoxesList;
