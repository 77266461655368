import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Loader from 'components/common-components/Loader';
import { CallAPI } from 'actions/General';

const ImageFieldPreview = ({ field }) => {
  const layoutProperties = field?.properties?.layoutProperties ?? null;
  const [fetchImageLoader, setFetchImageLoader] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState(null);

  const getImageUrl = async (url) => {
    let result = await CallAPI('GET_FORM_MEDIA_REDIRECT_URL', null, url, setFetchImageLoader, null);
    if (result.status) {
      return result.data?.url ?? null;
    } else return null;
  };

  const fetchData = async () => {
    if (layoutProperties?.fieldImage?.id) {
      const url = await getImageUrl(layoutProperties.fieldImage.id);
      if (url) setRedirectUrl(url);
    } else {
      setRedirectUrl(null);
    }
  };

  useEffect(() => {
    fetchData();
  }, [layoutProperties?.fieldImage?.id]);

  return (
    <div className='d-flex justify-content-center align-items-center'>
      {fetchImageLoader && <Loader color='green-1' />}
      {(layoutProperties?.fieldImageFile || redirectUrl) && !fetchImageLoader && (
        <img
          src={
            layoutProperties?.fieldImageFile
              ? URL.createObjectURL(layoutProperties.fieldImageFile)
              : redirectUrl
          }
          style={{ width: '15rem' }}
          alt='Loading...'
        />
      )}
    </div>
  );
};

ImageFieldPreview.propTypes = {
  field: PropTypes.object.isRequired,
};

export default ImageFieldPreview;
