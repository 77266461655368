import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import paperClipGreyIcon from 'assets/images/icons/paper-clip-grey.svg';
import pdfIcon from 'assets/images/icons/pdf-page-grey.svg';
import sendIcon from 'assets/images/icons/send.svg';
import trashIcon from 'assets/images/icons/trash-grey.svg';
import AlertModal from 'components/common-components/AlertModal';
import Loader from 'components/common-components/Loader';
import NestedSelect from 'components/form-components/NestedSelect';
import { CallAPI } from 'actions/General';
import { isRTL } from 'config/config';
import { uploadFile } from 'pages/company/utils';

const AddCommentModal = ({ commentsInfo, closeModal, isNestedModal }) => {
  const { t } = useTranslation();
  const auth = useSelector((state) => state.auth);
  const currentUserId = auth.profile.userCompanyId;
  const [comments, setComments] = useState([]);
  const [loading, setLoading] = useState(false);
  const containerRef = useRef(null);
  const urlsRef = useRef({});

  const addCommentPayload = {
    isTestSubmission: commentsInfo.isTestSubmission,
    submissionId: commentsInfo.submissionId,
    form: commentsInfo.formId,
    formTitle: commentsInfo.formTitle,
    formVersion: commentsInfo.formVersionId,
    submissionCreateByUserId: commentsInfo.submissionCreateByUserId,
  };

  const userInitials = ({ firstName, lastName }) =>
    [firstName, lastName]
      .map((word) => word?.charAt(0)?.toUpperCase() || '') // Safeguards against undefined or null
      .join('');

  const formattedTime = (dateStr) => {
    const date = new Date(dateStr);
    const formattedTime = new Intl.DateTimeFormat('en-US', {
      day: 'numeric',
      month: 'short',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    }).format(date);

    return formattedTime;
  };

  const getComments = async () => {
    const result = await CallAPI(
      'GET_SUBMISSION_COMMENTS',
      { submissionId: commentsInfo.submissionId },
      null,
      setLoading,
    );
    if (result.status) {
      for (const comment of result.data.comments) {
        for (const media of comment.media) {
          const url = await getCommentImageUrl(media);
          if (url) {
            urlsRef.current[media._id] = url;
          }
        }
      }
      setComments(result.data.comments);
    }
  };

  const getCommentImageUrl = async (media) => {
    const path = `${media.formId}/${media.versionId}/${media.customFolderId}/${media.imageName}`;
    let result = await CallAPI('GET_SUBMISSION_MEDIA_REDIRECT_URL', null, path, null, null);
    if (result.status) {
      return result.data.url;
    } else {
      return '';
    }
  };

  // this will show the latest comment by scrolling to the last comment
  useEffect(() => {
    const container = containerRef.current;
    if (container && comments.length) {
      container.scrollTop = container.scrollHeight; // go to the bottom end
    }
  }, [comments]);

  useEffect(() => {
    getComments();
  }, []);

  return (
    <AlertModal
      show={!!commentsInfo}
      showCloseButton
      closeModal={closeModal}
      actionButtons={[]}
      size='lg'
      backdropClassName={isNestedModal ? 'backdrop-overlap' : ''}
    >
      <div className='w-100'>
        <div className='border-bottom-grey-1-h-1 p-2 text-center'>
          <label className='theme-size-1_5'>{t('text_from_comments')}</label>
        </div>

        <div className='submission-comments-container'>
          {loading && <Loader color='green-1' />}
          <div ref={containerRef} className='submission-comments'>
            {comments.map((comment, index) => {
              return (
                <div
                  key={index}
                  className={`rounded-3 gap-2 ${
                    comment.fromGroupUser !== currentUserId ? 'received' : 'sent me-4'
                  }`}
                >
                  {comment.fromGroupUser !== currentUserId && (
                    <div className='start'>
                      <div className='profile-image d-flex justify-content-center align-items-center text-uppercase text-white'>
                        {userInitials(comment)}
                      </div>
                    </div>
                  )}

                  <div className='end'>
                    <div className='top-section d-flex align-items-center text-start'>
                      {comment.fromGroupUser !== currentUserId && (
                        <span className='title'>
                          {`${comment.firstName} ${comment.lastName}`} &nbsp;
                        </span>
                      )}
                      <span className='date'>{formattedTime(comment.createdAt)}</span>
                    </div>

                    {comment.media?.length > 0 && (
                      <div
                        className={`p-2 rounded attachmentContainer ${
                          comment.fromGroupUser !== currentUserId ? 'mt-3' : ''
                        }`}
                      >
                        {comment.media.map(
                          (item, index2) =>
                            item.mediaType === 'image' &&
                            urlsRef.current[item._id] && (
                              <div
                                key={index2}
                                className='border border-secondary rounded align-content-center'
                              >
                                <img
                                  src={urlsRef.current[item._id]}
                                  className='rounded cursor-pointer imgThumbnail'
                                  onClick={() => window.open(urlsRef.current[item._id], '_blank')}
                                />
                              </div>
                            ),
                        )}

                        {comment.media.map(
                          (item, index) =>
                            item.mediaType === 'file' &&
                            urlsRef.current[item._id] && (
                              <div
                                key={index}
                                className='d-inline-flex border border-secondary rounded p-2 gap-2 cursor-pointer'
                                onClick={() => window.open(urlsRef.current[item._id], '_blank')}
                              >
                                <img className='' src={pdfIcon} />
                                <span className='theme-text-green-1 truncate-string'>
                                  {index + 1}
                                </span>
                              </div>
                            ),
                        )}
                      </div>
                    )}

                    <div className='d-flex pt-2 detail-section'>
                      <span>{comment.message}</span>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <CommentInputField
            addCommentPayload={addCommentPayload}
            getComments={getComments}
            mediaCustomFolderId={commentsInfo.mediaCustomFolderId}
          />
        </div>
      </div>
    </AlertModal>
  );
};

AddCommentModal.propTypes = {
  commentsInfo: PropTypes.object,
  closeModal: PropTypes.func.isRequired,
  isNestedModal: PropTypes.bool,
};

const CommentInputField = ({ addCommentPayload, getComments, mediaCustomFolderId }) => {
  const { t } = useTranslation();
  const [newComment, setNewComment] = useState({ messageText: '', media: [] });
  const [fileType, setFileType] = useState({ type: '', triggerInput: false });
  const [loading, setLoading] = useState(false);
  const textareaRef = useRef(null);
  const fileInputRef = useRef(null);

  const attachmentOptionList = [
    { value: 'media', displayValue: 'select_photo_video' },
    { value: 'document', displayValue: 'select_document' },
  ];

  const handleTextChange = (value) => {
    setNewComment((prevState) => ({ ...prevState, messageText: value }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setNewComment((prevState) => ({ ...prevState, media: [file] }));
    }
    e.target.value = null;
  };

  const handleUnAttachMedia = () => {
    setNewComment((prev) => ({ ...prev, media: [] }));
  };

  const clearNewComment = () => setNewComment({ messageText: '', media: [] });

  const handleMediaUploads = async () => {
    const mediaIds = [];
    for (const mediaFile of newComment.media) {
      const response = await CallAPI(
        'GET_SUBMISSION_MEDIA_UPLOAD_URL',
        {
          imageName: `${Date.now()}${mediaFile.name.slice(mediaFile.name.lastIndexOf('.'))}`,
          mediaType: mediaFile.type.includes('application/') ? 'file' : 'image',
          formId: addCommentPayload.form,
          versionId: addCommentPayload.formVersion,
          customFolderId: mediaCustomFolderId,
        },
        null,
        null,
      );
      if (response.status) {
        await uploadFile(mediaFile, response.data, null);
        mediaIds.push(response.data.id);
      }
    }
    return mediaIds;
  };

  const addComment = async () => {
    if (newComment.messageText !== '') {
      setLoading(true);
      try {
        const mediaResponses = await handleMediaUploads();
        const payload = {
          ...addCommentPayload,
          message: newComment.messageText,
          messageType: 'text',
          media: mediaResponses,
        };
        const result = await CallAPI('ADD_SUBMISSION_COMMENT', payload, null);
        if (result.status) {
          getComments();
        }
        clearNewComment();
      } catch (error) {
        console.log('addComment error: ', error);
      }
      setLoading(false);
    }
  };

  // adjust textarea height when overflow occur
  useEffect(() => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = 'auto'; // Reset the height before recalculating
      if (textarea.scrollHeight < 150) textarea.style.height = `${textarea.scrollHeight + 2}px`;
      // Set height based on scrollHeight
      else textarea.style.height = `150px`; // Set height based on scrollHeight
    }
  }, [newComment]);

  useEffect(() => {
    if (fileType.triggerInput && fileInputRef.current) {
      fileInputRef.current.click();
    }
    fileType.triggerInput = false;
  }, [fileType]);

  return (
    <div>
      {newComment.media.length > 0 && (
        <div className='mt-3 p-2 rounded attachmentContainer'>
          {newComment.media[0].type.includes('image/') ? (
            <div className='border border-secondary rounded '>
              <img
                src={URL.createObjectURL(newComment.media[0])}
                className='rounded imgThumbnail'
                alt='attachment'
              />
              <img
                className='ms-2 cursor-pointer'
                src={trashIcon}
                onClick={() => handleUnAttachMedia()}
                alt='Delete'
              />
            </div>
          ) : (
            <div className='d-inline-flex border border-secondary rounded p-2'>
              <img src={pdfIcon} alt='PDF icon' />
              <span className='ms-2 theme-text-green-1 truncate-string'>
                {newComment.media[0].name}
              </span>
              <img
                className='ms-3 cursor-pointer'
                src={trashIcon}
                onClick={() => handleUnAttachMedia()}
                alt='Delete'
              />
            </div>
          )}
        </div>
      )}

      <div className='mt-3 custom-input-box d-flex align-items-center flex-column w-100'>
        <div className='d-flex w-100 align-items-center'>
          <div className='custom-input-field w-100'>
            <textarea
              ref={textareaRef}
              rows={1}
              type='text'
              style={{ resize: 'none' }}
              name={'message'}
              placeholder={t('text_type_here')}
              value={newComment.messageText}
              autoComplete='off'
              onChange={(e) => handleTextChange(e.target.value)}
            />
            <div className={`form-comment-icons form-comment-icons-${isRTL() ? 'rtl' : 'ltr'}`}>
              <input
                className='d-none'
                type='file'
                accept={fileType.type === 'media' ? '.jpg, .jpeg, .png' : 'application/pdf'}
                ref={fileInputRef}
                onChange={handleFileChange}
              />
              <NestedSelect
                options={attachmentOptionList}
                name='media'
                drop='up-centered'
                handleChange={(_, value) => setFileType({ type: value, triggerInput: true })}
                toggleComponent={<img src={paperClipGreyIcon} />}
                toggleClasses={`${newComment.media.length > 0 ? 'pe-none opacity-50' : ''}`} //disable click when meida attached
              />
              <img
                src={sendIcon}
                className='send-icon'
                onClick={() => (!loading ? addComment() : null)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

CommentInputField.propTypes = {
  addCommentPayload: PropTypes.object.isRequired,
  mediaCustomFolderId: PropTypes.string.isRequired,
  getComments: PropTypes.func.isRequired,
};

export default AddCommentModal;
