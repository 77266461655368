import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import backArrow from 'assets/images/icons/arrow-left.svg';
import sectionGreyIcon from 'assets/images/icons/section-grey.svg';
import AlertModal from 'components/common-components/AlertModal';
import Error from 'components/Error';
import FormButton from 'components/form-components/FormButton';
import FormLoadingButton from 'components/form-components/FormLoadingButton';
import SelectionComponent from 'components/common-components/SelectionComponent';
import { CallAPI } from 'actions/General';
import { getAllSectionsPermission } from './utils';

const UpdateSectionPermissions = ({ formDetails, selectedPermission, goBack, getFormDetail }) => {
  const { t } = useTranslation();
  const currentSections = useMemo(
    () =>
      formDetails.currentVersion.supervisorElements
        .filter((element) => element.elementType === 'section')
        .map((element) => ({
          elementGlobalId: element.elementGlobalId,
          sectionTitle: JSON.parse(element.properties).basicProperties.sectionTitle,
        })),
    [formDetails],
  );
  const [showAlert, setShowAlert] = useState(false);
  const [APIError, setAPIError] = useState('');
  const [loading, setLoading] = useState(false);
  const [sectionPermissions, setSectionPermissions] = useState(
    getAllSectionsPermission([...selectedPermission.sections], currentSections),
  );
  const [activeManagerSection, setActiveManagerSection] = useState(
    currentSections[0]?.elementGlobalId ?? null,
  );
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState([]);

  const submit = async () => {
    const permissionValues = sectionPermissions.map((permission) => ({
      ...permission,
      users: permission.users.map((user) => ({ userId: user.id, accessLevel: user.accessLevel })),
      groups: permission.groups.map((group) => ({
        groupId: group.id,
        accessLevel: group.accessLevel,
      })),
    }));
    const values = { formId: formDetails._id, [selectedPermission.key]: permissionValues };

    const result = await CallAPI('SAVE_FORM', values, null, setLoading, setAPIError, null);
    if (result.status) {
      setShowAlert(true);
      getFormDetail(formDetails._id);
    }
  };

  useEffect(() => {
    if (activeManagerSection) {
      const selectedSection = sectionPermissions.find(
        (section) => section.managerSectionGlobalId === activeManagerSection,
      );

      setSelectedGroups(selectedSection.groups);
      setSelectedUsers(selectedSection.users);
    }
  }, [activeManagerSection]);

  useEffect(() => {
    setSectionPermissions((prevList) => {
      return prevList.map((section) => {
        if (section.managerSectionGlobalId === activeManagerSection) {
          return { ...section, users: selectedUsers, groups: selectedGroups };
        }
        return section;
      });
    });
  }, [selectedUsers, selectedGroups]);

  return (
    <div>
      {showAlert && (
        <AlertModal
          show={showAlert}
          showCloseButton={true}
          closeModal={() => setShowAlert(false)}
          title='alert_success'
          message='alert_permission_updated'
          variant='success'
          actionButtons={[{ text: t('button_ok'), variant: 'green-1', onClick: () => goBack() }]}
        />
      )}
      <div className='row head'>
        <div className='col-4 d-flex align-items-center cursor-pointer' onClick={goBack}>
          <img src={backArrow} className='me-2' />
          <label className='theme-text-black-1'>{t('text_back')}</label>
        </div>
        <div className='col-4 title text-center'>
          {t(
            selectedPermission.key === 'appUser'
              ? 'text_app_users_are_allowed_to_use_this_form_on_mobile_app'
              : selectedPermission.key === 'supervisor' ||
                selectedPermission.key === 'managerSections'
              ? 'text_admins_are_allowed_to_view_data_on_web'
              : 'text_designers_are_allowed_to_make_changes_on_web',
          )}
        </div>
      </div>
      {APIError && <Error msg={APIError} />}
      {currentSections.length > 0 && (
        <>
          <div className='mb-3 mt-2'>
            <div className='d-flex gap-3 flex-wrap'>
              {currentSections.map((element, i) => (
                <div
                  className={`d-flex gap-2 align-items-center rounded-top cursor-pointer py-2 px-3${
                    activeManagerSection === element.elementGlobalId
                      ? ' theme-background-green-1 border-bottom-green-1-h-2'
                      : ' border-bottom-grey-1-h-2'
                  }`}
                  onClick={() => setActiveManagerSection(element.elementGlobalId)}
                  key={i}
                >
                  <img
                    className={activeManagerSection === element.elementGlobalId ? 'svg-white' : ''}
                    src={sectionGreyIcon}
                  />
                  <label
                    className={
                      activeManagerSection === element.elementGlobalId ? ' text-white' : ''
                    }
                  >
                    {element.sectionTitle}
                  </label>
                </div>
              ))}
            </div>
          </div>
          <SelectionComponent
            title='text_groups'
            type='groups'
            selectedDataList={selectedGroups}
            setSelectedList={setSelectedGroups}
            showButtons={false}
            accessLevelKey={selectedPermission.key}
          />
          <SelectionComponent
            classes='mt-4'
            title='text_users'
            selectedDataList={selectedUsers}
            setSelectedList={setSelectedUsers}
            showButtons={false}
            accessLevelKey={selectedPermission.key}
          />

          <div className='w-100 d-flex justify-content-end gap-3 mt-4'>
            <FormButton text='button_cancel' variant='white-1' onClick={goBack} />
            <FormLoadingButton
              text='button_save'
              variant='green-1'
              onClick={submit}
              loading={loading}
            />
          </div>
        </>
      )}
    </div>
  );
};

UpdateSectionPermissions.propTypes = {
  formDetails: PropTypes.object.isRequired,
  selectedPermission: PropTypes.object.isRequired,
  goBack: PropTypes.func.isRequired,
  getFormDetail: PropTypes.func.isRequired,
};

export default UpdateSectionPermissions;
