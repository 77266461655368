import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import infoCircleIcon from 'assets/images/icons/info-circle.svg';
import TextField from 'components/form-components/TextField';
import SingleCheckBoxField from 'components/form-components/SingleCheckBoxField';
import NestedQuestionsCheckBoxesList from './NestedQuestionsCheckBoxesList';

const CreateViewModal = ({
  values,
  setValues,
  setShowViewModal,
  submissionsHeaderList,
  viewData,
  handleAddOrUpdateCustomView,
}) => {
  const { t } = useTranslation();
  const handleChange = (field, value) => {
    setValues((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const createAndApplyView = async () => {
    const selectedFieldIds = Object.keys(values.selectedFields);
    if (selectedFieldIds.length && values.name) {
      const viewProps = {
        name: values.name,
        views: JSON.stringify(selectedFieldIds),
        restricted: values.restricted,
      };

      if (viewData?.find((existingView) => existingView._id === values._id)) {
        // update custom view
        await handleAddOrUpdateCustomView(viewProps, values._id);
      } else {
        // add custom view
        await handleAddOrUpdateCustomView(viewProps);
      }
    }
    setShowViewModal((prevState) => ({
      ...prevState,
      showManageViewModal: false,
      showCreateViewModal: false,
    }));
    // clear element checkboxes
    setValues({
      _id: '',
      name: '',
      restricted: false,
      selectedFields: {},
      selectedGroups: {},
    });
  };

  const handleCancel = () => {
    setShowViewModal((prevState) => ({
      ...prevState,
      showCreateViewModal: false,
      showViewListModal: false,
      showManageViewModal: false,
    }));
  };

  const groupedData = submissionsHeaderList.reduce((acc, item) => {
    if (item.tableType === 'user' || item.tableType === 'basicFields') {
      // For user or basicFields, group them under "user"
      if (!acc.user) {
        acc.user = {
          groupKey: 'user',
          headers: [],
        };
      }
      acc.user.headers.push(item);
    } else if (item.tableType === 'supervisorFields') {
      // For supervisorFields, group them by sectionTitle
      const groupKey = item.sectionTitle;
      if (!acc[groupKey]) {
        acc[groupKey] = {
          groupKey: groupKey,
          headers: [],
        };
      }
      acc[groupKey].headers.push(item);
    }
    return acc;
  }, {});

  const groupedHeaders = Object.values(groupedData); // Convert the grouped object into an array
  const toggleSelectAllCheckBoxes = (groupKey) => {
    setValues((prev) => {
      const updatedFields = { ...prev.selectedFields };
      const updatedGroups = { ...prev.selectedGroups };

      const currentGroup = groupedHeaders?.find((gH) => gH?.groupKey === groupKey);
      const filteredCurrentGroup =
        groupKey === 'user'
          ? currentGroup?.headers?.filter((obj) => obj.tableType === 'basicFields')
          : currentGroup?.headers;

      filteredCurrentGroup?.forEach(({ name }) => {
        if (updatedGroups[groupKey]) {
          delete updatedFields[name];
        } else {
          updatedFields[name] = true;
        }
      });

      if (updatedGroups[groupKey]) {
        delete updatedGroups[groupKey];
      } else {
        updatedGroups[groupKey] = true;
      }

      return { ...prev, selectedFields: updatedFields, selectedGroups: updatedGroups };
    });
  };

  return (
    <div className='create-view-modal'>
      <div className='py-2 px-3'>
        <TextField
          name='name'
          displayName='text_custom_view'
          placeholder='field_name'
          value={values.name}
          touched={true}
          handleChange={handleChange}
          classes='mb-3'
        />
        <div className='d-flex align-items-center mb-3'>
          <SingleCheckBoxField
            name='restricted'
            value={values.restricted}
            handleChange={handleChange}
            text={<span>{t('text_restrict')}</span>}
          />
          <img src={infoCircleIcon} />
        </div>

        {groupedHeaders?.map(({ groupKey, headers }, groupIndex) => (
          <section key={groupIndex}>
            <label className='theme-text-black-2 theme-font-jakartaSans-medium my-2'>
              {headers[0]?.sectionTitle ? headers[0]?.sectionTitle : t('text_app_user')}
            </label>

            <SingleCheckBoxField
              name={groupKey}
              value={values.selectedGroups?.[groupKey] || false}
              handleChange={() => toggleSelectAllCheckBoxes(groupKey)}
              text={<span className='theme-text-green-1'>{t('text_select_all')}</span>}
              classes='mb-2'
            />
            <NestedQuestionsCheckBoxesList
              key={groupIndex}
              groupKey={groupKey}
              groupedArray={headers}
              values={values}
              setValues={setValues}
            />
          </section>
        ))}
      </div>
      <div className='d-flex gap-2 justify-content-around my-2'>
        <label className='theme-text-red-1 cursor-pointer' onClick={() => handleCancel()}>
          {t('text_cancel')}
        </label>

        {(() => {
          const selectedFieldsLength = Object.keys(values.selectedFields ?? {}).length;
          const isValid = values.name && selectedFieldsLength > 0;
          return (
            <label
              className={`cursor-pointer ${isValid ? `theme-text-green-1` : `theme-text-grey-5`}`}
              onClick={() => isValid && createAndApplyView()}
            >
              {t('text_apply_and_save')}
            </label>
          );
        })()}
      </div>
    </div>
  );
};

CreateViewModal.propTypes = {
  values: PropTypes.object.isRequired,
  setValues: PropTypes.func.isRequired,
  setShowViewModal: PropTypes.func.isRequired,
  submissionsHeaderList: PropTypes.array.isRequired,
  viewData: PropTypes.array.isRequired,
  handleAddOrUpdateCustomView: PropTypes.func.isRequired,
};
export default CreateViewModal;
