import React, { useState } from 'react';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import selectArrow from 'assets/images/icons/dropdown-arrow-green.svg';
import SelectField from 'components/form-components/SelectField';
import { CallAPI } from 'actions/General';
import EmailInputModal from 'components/common-components/EmailInputModal';

const ReportActions = ({ dataType, previewId, htmlContent }) => {
  const actionList = [
    { value: 'share_link', displayValue: 'Copy Web Link', isNested: false },
    { value: 'share_pdf', displayValue: 'Share PDF Link', isNested: false },
    { value: 'send_pdf', displayValue: 'Send PDF Copy', isNested: false },
    { value: 'open_pdf', displayValue: 'Open PDF', isNested: false },
    { value: 'print_report', displayValue: 'Print', isNested: false },
  ];
  const [showEmailInputModal, setShowEmailInputModal] = useState(false);
  const [actionType, setActionType] = useState('');
  const [loader, setLoader] = useState(false);

  const handlePrintReport = (htmlData) => {
    const iframe = document.createElement('iframe');
    iframe.style.position = 'absolute';
    iframe.style.width = '0px';
    iframe.style.height = '0px';
    iframe.style.border = 'none';
    document.body.appendChild(iframe);
    const iframeDoc = iframe.contentWindow.document;
    iframeDoc.open();
    iframeDoc.write(htmlData);
    iframeDoc.close();
    iframe.contentWindow.focus();
    iframe.contentWindow.print();
    iframe.onload = () => {
      iframe.remove();
    };
  };

  const handleActions = (_, value) => {
    // eslint-disable-next-line no-undef
    const adminPanelUrl = process.env.REACT_APP_ADMIN_PANEL_URL;
    if (value === 'share_link') {
      const url = `${adminPanelUrl}/report/web_link/${dataType}/${previewId}`;
      toast('Copied report link to clipboard.', {
        style: { borderRadius: '8px', background: '#000', color: '#fff' },
      });
      navigator.clipboard.writeText(url);
    } else if (value === 'open_pdf') {
      const url = `${adminPanelUrl}/report/pdf_preview/${dataType}/${previewId}`;
      window.open(url, '_blank');
    } else if (value === 'share_pdf') {
      setShowEmailInputModal(true);
      setActionType('pdf_share_link');
    } else if (value === 'send_pdf') {
      setShowEmailInputModal(true);
      setActionType('pdf_send');
    } else if (value === 'print_report') {
      handlePrintReport(htmlContent);
    }
  };

  const sendPDFCopyToUser = async (email) => {
    await CallAPI(
      'GET_PREVIEW_DETAIL',
      { previewId: previewId, dataOf: dataType, action: actionType, email: email.email },
      null,
      setLoader,
      null,
      null,
    );
    setShowEmailInputModal(false);
  };

  const sendPDFCopy = async (email) => {
    toast.promise(
      sendPDFCopyToUser(email),
      {
        loading: 'Sending Email...',
        success: <span className='theme-text-white-1'>PDF Copy sent successfully.</span>,
        error: <span className='theme-text-white-1'>Something went wrong.</span>,
      },
      { style: { borderRadius: '8px', background: '#000', color: '#fff' } },
    );
  };

  return (
    <>
      <SelectField
        handleChange={handleActions}
        name='share'
        position='end'
        listValues={actionList}
        label={'Share'}
        icon={selectArrow}
        isAutoClose={true}
        classes='report-share'
      />
      {showEmailInputModal && (
        <EmailInputModal
          showModal={showEmailInputModal}
          setShowModal={setShowEmailInputModal}
          onSubmit={sendPDFCopy}
          loading={loader}
        />
      )}
    </>
  );
};

ReportActions.propTypes = {
  dataType: PropTypes.string.isRequired,
  previewId: PropTypes.string.isRequired,
  htmlContent: PropTypes.string.isRequired,
};

export default ReportActions;
